import { fetchImageForFarteEditor } from '@follow/farte'
import React, { FC, useEffect, useState } from 'react'

interface ImageRendererProps {
  token: string
  url: string
  usurpedUser?: { id: number; username: string }
}

export const ImageRenderer: FC<ImageRendererProps> = React.memo(({ token, url, usurpedUser }) => {
  const [src, setSrc] = useState(url)

  useEffect(() => {
    ;(async () => {
      const image = await fetchImageForFarteEditor(url, token, usurpedUser)

      // Gestion du cas où l'url aurait changé le temps que l'image soit fetchée
      if (image.requestUrl !== url) return

      setSrc(image.content)
    })()
  }, [url, token, usurpedUser])

  return <img src={src} max-width="100%" width="100%" />
})
ImageRenderer.displayName = 'ImageRenderer'
