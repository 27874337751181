import { generateAudiometerImageNodes } from '@follow/cdk'
import { TooltipOptions } from 'chart.js'

export const AUDIOGRAM_TOOLTIP_OPTIONS: DeepPartial<TooltipOptions<'line'>> = {
  displayColors: false,
  animations: false,
  position: 'nearest',
  yAlign: 'bottom',
  caretPadding: 10,
  callbacks: {
    title: () => '',
    label: ({ parsed: { x, y } }) => `${x} / ${y}`,
  },
}

const generateNodeFromSvgSrc = (svgSrc: string) => {
  const imageElement = new Image()
  imageElement.src = svgSrc

  return imageElement
}

export const AUDIODRAM_POINT_NODES = generateAudiometerImageNodes(false, generateNodeFromSvgSrc)
export const AUDIOGRAM_HOVER_POINT_NODES = generateAudiometerImageNodes(
  true,
  generateNodeFromSvgSrc,
)
