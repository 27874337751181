import React, { FC, useCallback } from 'react'
import { WeberCellValue } from '../../../../model/types/Audiometry.types'
import { WeberTestCellProps } from './WeberTestCell.model'
import { WeberTestCellStyled } from './WeberTestCell.styled'

const { Cell, Svg } = WeberTestCellStyled

const getNextWeberValue = (value: WeberCellValue): WeberCellValue => {
  switch (value) {
    case null:
      return 'neutral'
    case 'neutral':
      return 'left'
    case 'left':
      return 'right'
    case 'right':
      return null
  }
}

export const WeberTestCell: FC<WeberTestCellProps> = ({ value, disabled, onChange }) => {
  const handleClick = useCallback(() => {
    onChange(getNextWeberValue(value))
  }, [onChange, value])

  return (
    <Cell onClick={handleClick} disabled={!!disabled}>
      <Svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" version="1.1">
        <g
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          {value !== null && <polyline id="dash" points="2,16 30,16" />}
          {value === 'right' && <polyline id="right" points="22,8 30,16 22,24" />}
          {value === 'left' && <polyline id="left" points="10,8 2,16 10,24" />}
        </g>
      </Svg>
    </Cell>
  )
}
