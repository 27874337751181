import { VariablesData } from '@follow/farte'
import { RendererActionTypes } from './renderer.model'

export const getVariableData = () => ({
  type: RendererActionTypes.GET_VARIABLE_DATA,
})

export const setVariableData = (variableData: VariablesData | null) => ({
  type: RendererActionTypes.SET_VARIABLE_DATA,
  variableData,
})

export const setVariableDataLoading = (loading: boolean) => ({
  type: RendererActionTypes.SET_VARIABLE_DATA_LOADING,
  loading,
})

export const refetchVariableDataOnEditor = () => ({
  type: RendererActionTypes.REFETCH_VARIABLE_DATA_ON_EDITOR,
})
