import { useMutation } from '@tanstack/react-query'
import { createAudiometerTemplate } from '../../../data/audiometry'
import { UserFriendlyError } from '../../../model/Error'

interface UseCreateAudiometerTemplateVariables {
  documentId: number
}

export const useCreateAudiometerTemplate = () => {
  const mutation = useMutation({
    mutationFn: async ({ documentId }: UseCreateAudiometerTemplateVariables) => {
      const { data, ok } = await createAudiometerTemplate(documentId)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          "La création d'audiométrie a échoué",
          "Une erreur technique s'est produite",
        )
      }

      return data
    },
  })

  return mutation
}
