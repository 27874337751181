import { isDefined } from '@follow/cdk'
import { ChartEvent, Chart } from 'chart.js'
import { getRelativePosition } from 'chart.js/helpers'
import { AxisParams } from '../../model/Audiometry'
import { frameValue, getNearestMultiple } from '../math.utilities'

export const getClosestCoords = (
  event: ChartEvent,
  chart: Chart,
  xAxis: AxisParams,
  yAxis: AxisParams,
) => {
  const canvasPosition = getRelativePosition(event, chart as any)
  const xPos = chart.scales.x.getValueForPixel(canvasPosition.x)
  const yPos = chart.scales.y.getValueForPixel(canvasPosition.y)

  if (!isDefined(xPos) || !isDefined(yPos)) {
    return null
  }

  const closestX = getNearestMultiple(xPos, xAxis.interval)
  const closestY = getNearestMultiple(yPos, yAxis.interval)

  const framedX = frameValue(closestX, xAxis.min, xAxis.max)
  const framedY = frameValue(closestY, yAxis.min, yAxis.max)

  return { x: framedX, y: framedY }
}

/* Utilisé pour un graphe avec un axe X logarithmique et un Y linéaire */
export const getClosestCoordsHybrid = (
  event: ChartEvent,
  chart: Chart,
  xTicks: Array<number>,
  yAxis: AxisParams,
) => {
  const canvasPosition = getRelativePosition(event, chart as any)
  const xPos = chart.scales.x.getValueForPixel(canvasPosition.x)
  const yPos = chart.scales.y.getValueForPixel(canvasPosition.y)

  if (!isDefined(xPos) || !isDefined(yPos)) {
    return null
  }
  const closestX = xTicks.reduce((prev, curr) =>
    Math.abs(curr - xPos) < Math.abs(prev - xPos) ? curr : prev,
  )
  const closestY = getNearestMultiple(yPos, yAxis.interval)
  const framedY = frameValue(closestY, yAxis.min, yAxis.max)

  return { x: closestX, y: framedY }
}
