import React, { FC } from 'react'
import { audiogramPointSize, AudiogramRotativePointProps } from '../../AudiogramPoint.model'

export const BracketPoint: FC<AudiogramRotativePointProps> = ({
  color,
  lowerChevron,
  orientation,
}) => {
  return (
    <svg
      width={audiogramPointSize}
      height={audiogramPointSize}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <g stroke={color} fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
        <polyline
          id="chevron"
          strokeWidth="2"
          points={orientation === 'right' ? '11,10 16,10 16,22 11,22' : '21,10 16,10 16,22 21,22'}
        />
        {lowerChevron && <polyline id="chevron" points="18,24 21,27 24,24" />}
      </g>
    </svg>
  )
}
