import React, { FC } from 'react'
import { WeberTestProps } from './WeberTest.model'
import { WeberTestStyled } from './WeberTest.styled'
import { WeberTestCell } from './WeberTestCell'

const { Container, Grid } = WeberTestStyled

export const WeberTest: FC<WeberTestProps> = ({ data, disabled, onChange }) => {
  return (
    <Container>
      <Grid>
        <tbody>
          <tr>
            <th>250</th>
            <th>500</th>
            <th>1000</th>
            <th>2000</th>
            <th>4000</th>
          </tr>
          <tr>
            <WeberTestCell
              value={data.value250}
              onChange={(value) => !disabled && onChange?.({ value250: value })}
              disabled={disabled}
            />
            <WeberTestCell
              value={data.value500}
              onChange={(value) => !disabled && onChange?.({ value500: value })}
              disabled={disabled}
            />
            <WeberTestCell
              value={data.value1000}
              onChange={(value) => !disabled && onChange?.({ value1000: value })}
              disabled={disabled}
            />
            <WeberTestCell
              value={data.value2000}
              onChange={(value) => !disabled && onChange?.({ value2000: value })}
              disabled={disabled}
            />
            <WeberTestCell
              value={data.value4000}
              onChange={(value) => !disabled && onChange?.({ value4000: value })}
              disabled={disabled}
            />
          </tr>
        </tbody>
      </Grid>
    </Container>
  )
}
