import {
  ClickVariableCallback,
  DEFAULT_TOOLBAR_CONFIG,
  DOM_getTextByNodeKey,
  DOM_setSelectionRangeBySlateKey,
  EditorValue,
  findVariable,
  getWithVariableHtml,
  insertImage,
  insertText,
  replaceVariableByText,
  ToolbarConfig,
  updateVariableFixedValue,
  WithVariableEditor,
  VariablesData,
} from '@follow/farte'
import React, { FunctionComponent, useCallback, useContext, useMemo, useState } from 'react'
import { DocumentEditorWrapper } from '../../components/document'
import { copyTextPlain } from '../../misc/clipboard.utilities'
import { BASE_VARIABLES_LIST, EMPTY_VARIABLE_THEME } from '../../model/DocumentVariable'
import {
  isPrescriptionVariable,
  isQuestionnaireVariable,
  VariableKindPrefix,
  isQuestionVariable,
  isQuoteLineVariable,
  isAudiometerVariable,
  retrieveVariableId,
} from '@follow/cdk'
import { EditableDocument } from '../../store/domain/editor'
import { VariableConfiguration } from '../VariableConfiguration'
import { AddVariableButton, HistoryButton, ToolbarAdditionalCategory } from './ToolbarButton'
import { DocumentEditorProps } from './DocumentEditor.model'
import { FileDropzone } from '../../components/file'
import styles from './DocumentEditor.module.scss'
import { AuthService, ConnectedUserContext } from '../../misc/auth.utilities'
import { computePrescriptionVariableId } from '../../misc/drug.utilities'
import { AddDatamatrixButton } from './ToolbarButton/AddDatamatrixButton.component'
import { AddDatamatrixModal } from '../../components/datamatrix'
import { isDefined } from '../../misc/functions.utilities'

function getDocumentPrescriptionVariableById(
  editableDocument: EditableDocument,
  variableId: string,
) {
  return editableDocument.prescriptions.find(
    ({ prescriptionVariableUuid }) =>
      computePrescriptionVariableId(prescriptionVariableUuid) === variableId,
  )
}

function getDocumentQuestionVariableById(editableDocument: EditableDocument, variableId: string) {
  return editableDocument.variables.find(
    (variable) => `${VariableKindPrefix.QUESTION}_${variable.id}` === variableId,
  )
}

const getDocumentQuoteLineVariableById = (editableDocument: EditableDocument, variableId: string) =>
  editableDocument.quoteLines.find(
    (variable) => `${VariableKindPrefix.QUOTE_LINE}_${variable.variableUuid}` === variableId,
  )

function getDocumentQuestionnaireVariableById(
  editableDocument: EditableDocument,
  variableId: string,
) {
  return editableDocument.questionnaires.find(
    (questionnaire) => `${VariableKindPrefix.QUESTIONNAIRE}_${questionnaire.id}` === variableId,
  )
}

function getBaseVariableById(variableId: string) {
  return Object.values(BASE_VARIABLES_LIST).find((baseVariable) => baseVariable.id === variableId)
}

function getAudiometerIndexById(editableDocument: EditableDocument, variableId: string) {
  const audiometerId = retrieveVariableId(variableId)
  return editableDocument.audiometers.findIndex(({ id }) => audiometerId === id)
}

function getVariableTitleById(
  editableDocument: EditableDocument,
  variableId: string,
): string | undefined {
  const isPrescription = isPrescriptionVariable(variableId)
  const isQuestion = isQuestionVariable(variableId)
  const isQuestionnaire = isQuestionnaireVariable(variableId)
  const isQuoteLine = isQuoteLineVariable(variableId)
  const isAudiometer = isAudiometerVariable(variableId)
  if (isPrescription) {
    const drugVariable = getDocumentPrescriptionVariableById(editableDocument, variableId)
    if (drugVariable) {
      const names = drugVariable.drugs.map(({ name }) => name)
      return names.join(' et ')
    } else {
      return undefined
    }
  } else if (isQuestion) {
    const questionVariable = getDocumentQuestionVariableById(editableDocument, variableId)
    return questionVariable ? questionVariable.title : undefined
  } else if (isQuoteLine) {
    const quoteLineVariable = getDocumentQuoteLineVariableById(editableDocument, variableId)
    return quoteLineVariable ? quoteLineVariable.codeActe : undefined
  } else if (isQuestionnaire) {
    const questionnaireVariable = getDocumentQuestionnaireVariableById(editableDocument, variableId)
    return questionnaireVariable ? questionnaireVariable.title : undefined
  } else if (isAudiometer) {
    const audiometerIndex = getAudiometerIndexById(editableDocument, variableId)
    return isDefined(audiometerIndex) ? `Audiométrie #${audiometerIndex + 1}` : 'Audiométrie'
  } else {
    const baseVariable = getBaseVariableById(variableId)
    return baseVariable && isDefined(baseVariable.value) ? baseVariable.value : undefined
  }
}

const filterEmptyVariableData = (variableData: VariablesData): VariablesData =>
  Object.fromEntries(
    Object.entries(variableData).filter(
      ([_, variable]) => variable.customTheme !== EMPTY_VARIABLE_THEME,
    ),
  )

const DocumentEditor: FunctionComponent<DocumentEditorProps> = ({
  editableDocument,
  onEditQuestion,
  onSelectVariableDisplayConfig,
  setValue,
  value,
  variablesData,
  isReadonly,
  isDisabled,
  enableVariableFixedValues,
  copyHtmlToClipboard,
  onClickAddVariable,
  onClickHistory,
  addImageToDocument,
  onChangeVariablePosition,
  onRemoveVariable,
  onPaste,
}) => {
  const [displayDropzone, setDisplayDropzone] = useState(false)
  const [displayDatamatrixModal, setDisplayDatamatrixModal] = useState(false)
  const [authToken, setAuthToken] = useState(AuthService.getToken())
  const { usurpedUser } = useContext(ConnectedUserContext)

  const addDatamatrix = useCallback(
    (datamatrix: string) => {
      setValue((currentValue) => insertText(currentValue, datamatrix))
      setDisplayDatamatrixModal(false)
    },
    [setValue],
  )

  const configureQuestion = (variableId: string) => {
    if (onEditQuestion) {
      const selectedVariable = getDocumentQuestionVariableById(editableDocument, variableId)
      if (selectedVariable) {
        onEditQuestion(selectedVariable)
      }
    }
  }

  const handleAddImage = (files: File[]) => {
    setDisplayDropzone(false)
    if (files.length > 0) {
      addImageToDocument(files[0], (imageUrl) => {
        setAuthToken(AuthService.getToken())
        setValue(insertImage(value, imageUrl))
      })
    }
  }

  const handleVariableSecondaryAction: ClickVariableCallback = ({ variableId }) => {
    if (onEditQuestion && isQuestionVariable(variableId)) {
      configureQuestion(variableId)
    }
  }

  const handleCopyVariable = (slateKey: string) => {
    DOM_setSelectionRangeBySlateKey(slateKey)
    window.document.execCommand('copy')
  }

  const handleUpdateVariableFixedValue = useMemo(() => {
    if (!enableVariableFixedValues) return undefined

    return (variableId: string) => {
      const variableNode = findVariable(value, variableId)
      if (variableNode) {
        setValue(
          updateVariableFixedValue(value, variableNode.key, variableId, variablesData[variableId]),
        )
      }
    }
  }, [enableVariableFixedValues, value, setValue, variablesData])

  const handleCopyVariableText = (slateKey: string) => {
    const text = DOM_getTextByNodeKey(slateKey)
    copyTextPlain(text)
  }

  const handleChangeVariableToText = (slateKey: string) => {
    // La RegExp n'est pas globale, elle ne remplace que la première occurence
    const DISPLAY_MODE_PATTERN = /(\[[\w|\s]+\])|\[\]/
    const newValue = replaceVariableByText(value, slateKey, (text) =>
      text.replace(DISPLAY_MODE_PATTERN, ''),
    )
    setValue(newValue)
  }

  const handleValueChange = (editorValue: EditorValue) => {
    setValue(editorValue)
  }

  const handleSaveAsText = () => {
    if (copyHtmlToClipboard) {
      const cleaned = filterEmptyVariableData(variablesData)
      const html = getWithVariableHtml(value, cleaned)
      copyHtmlToClipboard(html)
    }
  }

  const toolBarConfig: ToolbarConfig = copyHtmlToClipboard
    ? {
        ...DEFAULT_TOOLBAR_CONFIG,
        categories: { ...DEFAULT_TOOLBAR_CONFIG.categories, save: ['saveText'] },
      }
    : DEFAULT_TOOLBAR_CONFIG

  return (
    <>
      <DocumentEditorWrapper>
        <WithVariableEditor
          toolbarStyle={{ zIndex: 1, position: 'sticky', top: 'var(--navbar-height)' }}
          token={authToken}
          usurpedUser={usurpedUser}
          mode="edit"
          value={value}
          variableData={variablesData}
          onValueChange={handleValueChange}
          onPaste={onPaste}
          readOnly={isReadonly}
          disabled={isDisabled}
          toolbarConfig={toolBarConfig}
          saveAsText={handleSaveAsText}
          onAddImage={() => setDisplayDropzone(true)}
          enableVariableFixedValues={enableVariableFixedValues}
          variableFallbackTheme={EMPTY_VARIABLE_THEME}
          toolbarAdditionalContent={
            <>
              <ToolbarAdditionalCategory>
                <AddDatamatrixButton onClick={() => setDisplayDatamatrixModal((value) => !value)} />
              </ToolbarAdditionalCategory>
              {onClickAddVariable && (
                <ToolbarAdditionalCategory>
                  <AddVariableButton onClick={onClickAddVariable} />
                </ToolbarAdditionalCategory>
              )}
              <ToolbarAdditionalCategory>
                <HistoryButton onClick={onClickHistory} updatedAt={editableDocument.updatedAt} />
              </ToolbarAdditionalCategory>
            </>
          }
          onVariableSecondaryAction={handleVariableSecondaryAction}
          renderVariableContextualContent={(slateKey, variableContext, variableId, hide) => {
            const variableTitle = getVariableTitleById(editableDocument, variableId)

            return (
              <VariableConfiguration
                variableId={variableId}
                variableTitle={variableTitle}
                variableContext={variableContext}
                editorValue={value}
                variablesData={variablesData}
                onConfigureQuestion={onEditQuestion && configureQuestion}
                onSelectVariableDisplayConfig={(mode, newVariableId) => {
                  onSelectVariableDisplayConfig(slateKey, newVariableId ?? variableId, mode)
                  hide()
                }}
                onCopyVariable={() => {
                  handleCopyVariable(slateKey)
                  hide()
                }}
                onCopyVariableText={() => {
                  handleCopyVariableText(slateKey)
                  hide()
                }}
                onChangeVariableToText={() => {
                  handleChangeVariableToText(slateKey)
                  hide()
                }}
                onChangeVariablePosition={onChangeVariablePosition}
                onRemoveVariable={onRemoveVariable}
                hide={hide}
                onUpdateVariableFixedValue={handleUpdateVariableFixedValue}
              />
            )
          }}
        />
      </DocumentEditorWrapper>
      {displayDropzone && (
        <div className={styles.dropZone}>
          <FileDropzone
            type="image"
            multiple={false}
            onDrop={handleAddImage}
            onClose={() => setDisplayDropzone(false)}
          />
        </div>
      )}
      <AddDatamatrixModal
        display={displayDatamatrixModal}
        addDatamatrix={addDatamatrix}
        onCancel={() => setDisplayDatamatrixModal(false)}
      />
    </>
  )
}

export default DocumentEditor
