import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateToneAudiogram } from '../../../data/audiometry'
import { ToneAudiogram } from '../../../model/Audiometry'
import { UserFriendlyError } from '../../../model/Error'
import { refetchVariableDataOnEditor } from '../../../store/renderer'
import { useActionDispatch } from '../../utils'
import { audiometryKeys } from './audiometry.keys'

interface UseUpdateToneAudiogramVariables {
  audiogramId: number
  payload: Partial<ToneAudiogram>
}

export const useUpdateToneAudiogram = () => {
  const queryClient = useQueryClient()

  const refetchVariablesIfEditorIsActive = useActionDispatch(refetchVariableDataOnEditor)

  const mutation = useMutation({
    mutationFn: async ({ audiogramId, payload }: UseUpdateToneAudiogramVariables) => {
      const { data, ok } = await updateToneAudiogram(audiogramId, payload)

      if (!data || !ok) {
        throw new UserFriendlyError('error', "La mise à jour de l'audiogramme tonal a échoué")
      }

      return data
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: audiometryKeys.audiometerInstance(data.audiometerId),
      })
      refetchVariablesIfEditorIsActive()
    },
  })

  return mutation
}
