import { connect } from 'react-redux'
import {
  medicalEventDomainActions,
  selectedDocumentQuestionnairesWithFilledIndicatorSelector,
  selectedDocumentQuestionListWithFilledIndicatorSelector,
  selectedDocumentDrugs,
  selectedDocumentActes,
  selectedDocumentAudiometers,
} from '../../../../store/domain/medicalEvents/index'
import { RootState } from '../../../../store/index'
import {
  selectedContentFilterSelector,
  medicalEventContentUiActions,
} from '../../../../store/ui/medicalEvents/medicalEventContent/index'
import { ContentFilters } from './ContentFilters.component'
import {
  QuestionnaireFiltersDispatchProps,
  QuestionnaireFiltersStoreProps,
} from './ContentFilters.model'
import {
  inUseMedicalEventSelector,
  selectedMedicalEventDocumentSelector,
} from '../../../../store/ui/medicalEvents'

const mapStateToProps = (state: RootState): QuestionnaireFiltersStoreProps => ({
  medicalEvent: inUseMedicalEventSelector(state),
  questionnaires: selectedDocumentQuestionnairesWithFilledIndicatorSelector(state),
  prescriptions: selectedDocumentDrugs(state),
  actes: selectedDocumentActes(state),
  audiometers: selectedDocumentAudiometers(state),
  documentQuestionList: selectedDocumentQuestionListWithFilledIndicatorSelector(state),
  selectedContentFilter: selectedContentFilterSelector(state),
  selectedMedicalEventDocument: selectedMedicalEventDocumentSelector(state),
})

const mapDispatchToProps: QuestionnaireFiltersDispatchProps = {
  initializeFiltersFromUrl: medicalEventDomainActions.initializeContentFilterFromUrl,
  selectMedicalEventContent: medicalEventContentUiActions.selectMedicalEventContent,
  deleteQuestionnaireFromSelectedDocument:
    medicalEventDomainActions.deleteQuestionnaireFromSelectedDocument,
  deleteDrugFromSelectedDocument: medicalEventDomainActions.deleteDrugFromSelectedDocument,
  deleteQuoteLineFromSelectedDocument:
    medicalEventDomainActions.deleteQuoteLineFromSelectedDocument,
  deleteAudiometerFromSelectedDocument:
    medicalEventDomainActions.deleteAudiometerFromSelectedDocument,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(ContentFilters)

export { withConnect as ContentFilters }
