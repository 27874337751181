import { FunctionComponent } from 'react'

import { AnswerRenderer } from './AnswerRenderer.component'
import { DangerousHtml } from './DangerousHtml.component'
import { ListRenderer } from './ListRenderer.component'
import { PrintHeader } from './PrintHeader.component'
import PrintFooter from './PrintFooter.component'
import { PrintDocumentBody } from './PrintDocumentBody.component'
import { ImageRenderer } from './ImageRenderer.component'

const ComponentsStore: { [componentName: string]: FunctionComponent<any> } = {
  AnswerRenderer,
  DangerousHtml,
  ListRenderer,
  PrintHeader,
  PrintFooter,
  PrintDocumentBody,
  ImageRenderer,
}
export default ComponentsStore

export * from './PrintHeader.component'
export * from './PrintFooter.component'
export * from './PrintDocumentBody.component'
export * from './AnswerRenderer.component'
export * from './ListRenderer.component'
export * from './DangerousHtml.component'
export * from './Debugger.component'
export * from './audiometry'
