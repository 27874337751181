import { ChartOptions } from 'chart.js'
import { displayRangeLabelEveryInterval } from '../../../utilities/audiometry.utils'
import { AudiogramAxis } from '../../types/Audiometry.types'
import { AUDIOMETRY_ELEMENTS_OPTIONS, AUDIOMETRY_TICKS_OPTIONS } from './Audiometry.enums'

export const SPEECH_AUDIOGRAM_AXIS: AudiogramAxis = {
  x: {
    interval: 10,
    min: 0,
    max: 100,
  },
  y: {
    interval: 5,
    min: 0,
    max: 100,
  },
}

export const SPEECH_AUDIOGRAM_CONFIG: ChartOptions<'line'> = {
  animation: false,
  clip: false,
  aspectRatio: 0.5,
  scales: {
    x: {
      type: 'linear',
      position: 'top',
      min: SPEECH_AUDIOGRAM_AXIS.x.min,
      max: SPEECH_AUDIOGRAM_AXIS.x.max,
      ticks: {
        ...AUDIOMETRY_TICKS_OPTIONS,
        stepSize: SPEECH_AUDIOGRAM_AXIS.x.interval,
      },
    },
    y: {
      type: 'linear',
      position: 'left',
      reverse: true,
      min: SPEECH_AUDIOGRAM_AXIS.y.min,
      max: SPEECH_AUDIOGRAM_AXIS.y.max,
      ticks: {
        ...AUDIOMETRY_TICKS_OPTIONS,
        stepSize: SPEECH_AUDIOGRAM_AXIS.y.interval,
      },
      afterTickToLabelConversion: displayRangeLabelEveryInterval(10),
    },
  },
  elements: AUDIOMETRY_ELEMENTS_OPTIONS,
  plugins: {
    annotation: {
      annotations: {
        diagonalLine: {
          type: 'line',
          xMin: 0,
          yMax: 20,
          borderWidth: 1,
          borderColor: 'black',
        },
      },
    },
  },
}
