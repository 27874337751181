import { ChartOptions } from 'chart.js'
import { displayRangeLabelEveryInterval } from '../../../utilities/audiometry.utils'
import { AudiogramAxis } from '../../types/Audiometry.types'
import { AUDIOMETRY_TICKS_OPTIONS, AUDIOMETRY_ELEMENTS_OPTIONS } from './Audiometry.enums'

export const TYMPANOGRAM_AXIS: AudiogramAxis = {
  x: {
    interval: 50,
    min: -400,
    max: 200,
  },
  y: {
    interval: 2,
    min: 0,
    max: 10,
  },
}

export const TYMPANOGRAM_BASE_CONFIG: ChartOptions<'line'> = {
  animation: false,
  clip: false,
  aspectRatio: 1,
  scales: {
    x: {
      type: 'linear',
      position: 'bottom',
      min: TYMPANOGRAM_AXIS.x.min,
      max: TYMPANOGRAM_AXIS.x.max,
      ticks: {
        ...AUDIOMETRY_TICKS_OPTIONS,
        stepSize: TYMPANOGRAM_AXIS.x.interval,
        autoSkip: false,
      },
      afterTickToLabelConversion: displayRangeLabelEveryInterval(100),
    },
    y: {
      type: 'linear',
      position: 'left',
      min: TYMPANOGRAM_AXIS.y.min,
      max: TYMPANOGRAM_AXIS.y.max,
      ticks: {
        ...AUDIOMETRY_TICKS_OPTIONS,
        stepSize: TYMPANOGRAM_AXIS.y.interval,
      },
    },
  },
  elements: AUDIOMETRY_ELEMENTS_OPTIONS,
  plugins: {
    annotation: {
      annotations: {
        zero: {
          type: 'line',
          xMin: 0,
          xMax: 0,
          borderWidth: 1,
          borderColor: 'black',
        },
      },
    },
  },
}
