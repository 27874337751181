export const getNearestMultiple = (input: number, multiple: number) => {
  const res = Math.round(input / multiple) * multiple
  return res
}

export const frameValue = (value: number, min: number, max: number) => {
  if (value < min) {
    return min
  }
  if (value > max) {
    return max
  }
  return value
}

export const parseFiniteNumber = (input: string) => {
  const parsed = parseInt(input)
  return Number.isFinite(parsed) ? parsed : null
}
