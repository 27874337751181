import { ChartOptions } from 'chart.js'
import { AudiogramAxis, LogarithmicAxisParams } from '../../types/Audiometry.types'
import { AUDIOMETRY_ELEMENTS_OPTIONS, AUDIOMETRY_TICKS_OPTIONS } from './Audiometry.enums'

export const TONE_AUDIOGRAM_AXIS: AudiogramAxis<LogarithmicAxisParams> = {
  x: {
    min: 125,
    max: 8000,
    ticks: [125, 250, 500, 1000, 2000, 3000, 4000, 6000, 8000],
    hiddenTicks: [3000, 6000],
  },
  y: {
    min: 0,
    max: 120,
    interval: 10,
  },
}

export const TONE_AUDIOGRAM_CONFIG: ChartOptions<'line'> = {
  animation: false,
  clip: false,
  aspectRatio: 1,
  scales: {
    x: {
      type: 'logarithmic',
      position: 'bottom',
      min: 125,
      max: 8000,
      ticks: {
        ...AUDIOMETRY_TICKS_OPTIONS,
      },
      afterBuildTicks: (axis) =>
        (axis.ticks = TONE_AUDIOGRAM_AXIS.x.ticks.map((value) => ({ value }))),
      afterTickToLabelConversion: (axis) =>
        axis.ticks.forEach(
          (tick) =>
            (tick.label = TONE_AUDIOGRAM_AXIS.x.hiddenTicks.includes(tick.value)
              ? ''
              : `${tick.value}`),
        ),
    },
    y: {
      type: 'linear',
      position: 'left',
      reverse: true,
      min: TONE_AUDIOGRAM_AXIS.y.min,
      max: TONE_AUDIOGRAM_AXIS.y.max,
      ticks: {
        ...AUDIOMETRY_TICKS_OPTIONS,
        stepSize: TONE_AUDIOGRAM_AXIS.y.interval,
      },
    },
  },
  elements: AUDIOMETRY_ELEMENTS_OPTIONS,
}

export const TONE_AUDIOGRAM_DASH = 7
