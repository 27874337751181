import React, { FC } from 'react'
import { AudiogramIconOptions } from '../../../model/types/Audiometry.types'
import { AudiogramPointProps } from './AudiogramPoint.model'
import {
  BracketPoint,
  ChevronPoint,
  CrossPoint,
  RectanglePoint,
  CirclePoint,
  TrianglePoint,
} from './points'

export const AudiogramPoint: FC<AudiogramIconOptions> = ({ color, mode, side, unheard }) => {
  const commonProps: AudiogramPointProps = {
    color,
    lowerChevron: unheard,
  }

  if (side === 'left') {
    switch (mode) {
      case 'maskedBoneConduction':
        return <BracketPoint {...commonProps} orientation="right" />
      case 'boneConduction':
        return <ChevronPoint {...commonProps} orientation="right" />
      case 'helplessFreeField':
        return <CrossPoint {...commonProps} />
      case 'maskedHelplessFreeField':
        return <RectanglePoint {...commonProps} />
      case 'maskedHelpedFreeField':
        return <RectanglePoint {...commonProps} point />
      case 'helpedFreeField':
        return <RectanglePoint {...commonProps} fill />
    }
  } else {
    switch (mode) {
      case 'maskedBoneConduction':
        return <BracketPoint {...commonProps} orientation="left" />
      case 'boneConduction':
        return <ChevronPoint {...commonProps} orientation="left" />
      case 'helplessFreeField':
        return <CirclePoint {...commonProps} />
      case 'maskedHelplessFreeField':
        return <TrianglePoint {...commonProps} />
      case 'maskedHelpedFreeField':
        return <TrianglePoint {...commonProps} point />
      case 'helpedFreeField':
        return <TrianglePoint {...commonProps} fill />
    }
  }

  return null
}
