import { EditorValueJSON } from '@follow/farte'
import { DocumentCategory } from './DocumentCategory'
import { Prescription } from './Prescription'
import { CustomFile } from './File'

import { QuestionnaireTemplate, QuestionTemplate } from './Questionnaire'
import { AuditFields, PermissionFields } from './Resource'
import { User } from './User'
import { ManualPrescription } from './ManualPrescription'
import { DocumentContentType, FieldValue, FwDocumentType } from './Document'
import { QuoteLine } from './Acte'
import { AudiometerTemplate } from './Audiometry'

export interface DocumentTemplateListItem extends PermissionFields {
  id: number
  title: string
  category: DocumentCategory
  tagIds: ReadonlyArray<string>
  owner: User
  type: FwDocumentType
  private: boolean
  contentType: DocumentContentType
}

interface BaseDocumentTemplate extends AuditFields, PermissionFields {
  id: number
  title: string
  category: DocumentCategory
  type: FwDocumentType
  tagIds: ReadonlyArray<string>
  owner: User
  private: boolean
  contentType: DocumentContentType
}

export interface FarteDocumentTemplate extends BaseDocumentTemplate {
  type: 'farte'
  readonly hash: string | null
  template: EditorValueJSON
  prescriptions: ReadonlyArray<Prescription>
  manualPrescriptions: ReadonlyArray<ManualPrescription>
  questionnaires: ReadonlyArray<QuestionnaireTemplate>
  variables: ReadonlyArray<QuestionTemplate>
  documentLayoutId: number | null
  quoteLines: ReadonlyArray<QuoteLine>
  audiometers: Array<AudiometerTemplate>
}

export interface PdfDocumentTemplate extends BaseDocumentTemplate {
  type: 'pdf'
  fieldValues: FieldValue[]
  file: CustomFile
  fileId?: number
}

export type DocumentTemplate = FarteDocumentTemplate | PdfDocumentTemplate

export interface DocumentTemplateSuggestionList {
  algorithm: string
  documentTemplates: DocumentTemplateListItem[]
}

export enum DocumentTemplateEditorVariantType {
  EDITOR = 'editor',
  QUESTIONNAIRE = 'questionnaire',
  VARIABLES = 'variables',
  ADD_VARIABLE_PANEL = 'add_variable_panel',
  PRESCRIPTION = 'prescription',
  ACTE = 'acte',
  AUDIOMETER = 'audiometer',
}

export interface DocumentTemplateEditorVariantEditor {
  type: DocumentTemplateEditorVariantType.EDITOR
}

export interface DocumentTemplateEditorVariantQuestionnaire {
  type: DocumentTemplateEditorVariantType.QUESTIONNAIRE
  questionnaireId: number
}

export interface DocumentTemplateEditorVariantPrescription {
  type: DocumentTemplateEditorVariantType.PRESCRIPTION
  prescriptionId: string
  intervalId?: string
}

export interface DocumentTemplateEditorVariantActe {
  type: DocumentTemplateEditorVariantType.ACTE
  acteId: string
}

export interface DocumentTemplateEditorVariantAudiometer {
  type: DocumentTemplateEditorVariantType.AUDIOMETER
  audiometerId: number
}

export interface DocumentTemplateEditorVariantVariables {
  type: DocumentTemplateEditorVariantType.VARIABLES
}

export interface DocumentTemplateEditorVariantAddVariablePanel {
  type: DocumentTemplateEditorVariantType.ADD_VARIABLE_PANEL
}

export type DocumentTemplateEditorVariant =
  | DocumentTemplateEditorVariantEditor
  | DocumentTemplateEditorVariantQuestionnaire
  | DocumentTemplateEditorVariantVariables
  | DocumentTemplateEditorVariantAddVariablePanel
  | DocumentTemplateEditorVariantPrescription
  | DocumentTemplateEditorVariantActe
  | DocumentTemplateEditorVariantAudiometer
