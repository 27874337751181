import { deserializeAudiometerInstance } from '@follow/cdk'
import { ApiResponse } from 'apisauce'
import { AudiometerInstance } from '../../../model/Audiometry'

export const deserializeAudiometerInstanceResponse = (payload: ApiResponse<AudiometerInstance>) => {
  if (!payload.data || !payload.ok) {
    return payload
  }

  const deserializedData = deserializeAudiometerInstance(payload.data)
  return {
    ...payload,
    data: deserializedData,
  }
}
