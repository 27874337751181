import { put, takeEvery } from 'redux-saga/effects'
import { FwTrackingEvent, TrackingService } from '../../../../misc/Tracking/index'
import { uiSagasFactory } from '../../../restux/ui/restuxUiSagas.factory'
import { serializeMedicalEventFiltersToUrl } from '../medicalEventUi.utilities'
import { medicalEventContentUiActions } from './medicalEventContent.actions'
import {
  MedicalEventContentUiActionTypes,
  medicalEventQuestionnairesConfig,
  SelectedContentType,
} from './medicalEventContent.model'
import { UrlQueryParams } from '../../../domain/medicalEvents/index'
import { RestuxLoadingState } from '../../../restux/ui'

const paginationSagas = uiSagasFactory(medicalEventQuestionnairesConfig)

/**
 * Gestion de la selection d'un filtre de type "Questionnaire" / "variables"
 */
function* selectMedicalEventContentWorker({
  filter,
  withFetch,
}: ReturnType<typeof medicalEventContentUiActions.selectMedicalEventContent>) {
  /* *** ANALYTICS *** */
  TrackingService.sendEvent(FwTrackingEvent.SELECT_MEDICAL_EVENT_QUESTIONNAIRE)
  yield put(medicalEventContentUiActions.setInUseLoading(RestuxLoadingState.IDLE))

  if (filter.type === SelectedContentType.QUESTIONNAIRE) {
    yield put(medicalEventContentUiActions.useId(filter.questionnaireId, { withFetch }))
  } else if (filter.type === SelectedContentType.PRESCRIPTION) {
    yield put(medicalEventContentUiActions.useId(filter.prescriptionId, { withFetch }))
  } else if (filter.type === SelectedContentType.ACTE) {
    yield put(medicalEventContentUiActions.useId(filter.acteId, { withFetch }))
  } else if (filter.type === SelectedContentType.AUDIOMETER) {
    yield put(medicalEventContentUiActions.useId(filter.audiometerId, { withFetch: false }))
  } else {
    yield put(medicalEventContentUiActions.useId(null))
  }

  const filters = [
    { name: 'contentType', value: filter.type },
    ...(filter.type === SelectedContentType.QUESTIONNAIRE
      ? [{ name: 'questionnaireId', value: `${filter.questionnaireId}` }]
      : []),
    ...(filter.type === SelectedContentType.ACTE
      ? [{ name: 'acteId', value: `${filter.acteId}` }]
      : []),
    ...(filter.type === SelectedContentType.AUDIOMETER
      ? [{ name: 'audiometerId', value: `${filter.audiometerId}` }]
      : []),
    ...(filter.type === SelectedContentType.PRESCRIPTION
      ? [
          { name: 'prescriptionId', value: `${filter.prescriptionId}` },
          ...(filter.intervalId === undefined
            ? []
            : [{ name: 'intervalId', value: `${filter.intervalId}` }]),
        ]
      : []),
  ]
  serializeMedicalEventFiltersToUrl(filters, [UrlQueryParams.DOC_ID, UrlQueryParams.SENDING])
}

function* onSelectMedicalEventContentWatcher() {
  yield takeEvery(MedicalEventContentUiActionTypes.SELECT_CONTENT, selectMedicalEventContentWorker)
}

export const medicalEventQuestionnaires = {
  ...paginationSagas,
  onSelectMedicalEventContentWatcher,
}
