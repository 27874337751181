import { put, takeEvery, select } from 'redux-saga/effects'
import { FwTrackingEvent, TrackingService } from '../../../misc/Tracking'
import { medicalEventFilesActions } from './medicalEventFiles'
import { uiSagasFactory } from '../../restux/ui/restuxUiSagas.factory'
import { medicalEventDocumentInstancesActions } from './medicalEventDocumentInstances/medicalEventDocumentInstances.actions'
import { medicalEventContentUiActions } from './medicalEventContent/index'
import { medicalEventUiActions } from './medicalEvents.actions'
import { medicalEventConfig, MedicalEventsUiActionTypes } from './medicalEvents.model'
import { serializeMedicalEventFiltersToUrl } from './medicalEventUi.utilities'
import { MedicalEventDocumentType } from '../../../model/MedicalEvent'
import { UrlQueryParams } from '../../domain/medicalEvents'
import { medicalEventsActions } from '../../cache/medicalEvents'
import { getCurrentPatientId } from '../../../misc/currentPatient.utilities'

const paginationSagas = uiSagasFactory(medicalEventConfig)

/**
 * Gestion de la selection d'un filtre de type "document"
 * Peut etre "Notes" / "Bilan" / "CRO" / "Document" / "Image" / "Fichier"
 */
function* selectDocumentWorker({
  filter,
  keepParams,
  sending,
}: ReturnType<typeof medicalEventUiActions.selectMedicalEventDocument>) {
  if (!filter) return

  yield put(medicalEventUiActions.filterMedicalEventDocument(filter))

  const params = keepParams
    ? [
        UrlQueryParams.CONTENT_TYPE,
        UrlQueryParams.PRESCRIPTION,
        UrlQueryParams.QUESTIONNAIRE_ID,
        UrlQueryParams.INTERVAL_ID,
        UrlQueryParams.SENDING,
        UrlQueryParams.ACTE_ID,
        UrlQueryParams.AUDIOMETER_ID,
      ]
    : []

  switch (filter.medicalEventDocumentType) {
    case MedicalEventDocumentType.FW_DOCUMENT:
      /* *** ANALYTICS *** */
      TrackingService.sendEvent(FwTrackingEvent.SELECT_MEDICAL_EVENT_DOCUMENT)

      serializeMedicalEventFiltersToUrl(
        [{ name: UrlQueryParams.DOC_ID, value: `${filter.id}` }],
        params,
      )
      yield put(medicalEventDocumentInstancesActions.useId(filter.id))
      if (filter.resetSelectedQuestionnaire) {
        yield put(medicalEventContentUiActions.useId(null))
      }
      break

    case MedicalEventDocumentType.FILE:
      /* *** ANALYTICS *** */
      TrackingService.sendEvent(FwTrackingEvent.SELECT_MEDICAL_EVENT_FILE)
      serializeMedicalEventFiltersToUrl(
        [{ name: UrlQueryParams.FILE_ID, value: `${filter.id}` }],
        params,
      )
      const fileInCache = yield select((state) => state.cache.files.details[filter.id])
      yield put(medicalEventFilesActions.useId(filter.id, { withFetch: !fileInCache }))
      break

    case MedicalEventDocumentType.OBSERVATIONS:
      /* *** ANALYTICS *** */
      TrackingService.sendEvent(FwTrackingEvent.SELECT_MEDICAL_EVENT_OBSERVATIONS)

      serializeMedicalEventFiltersToUrl(
        sending ? [{ name: UrlQueryParams.SENDING, value: sending }] : [],
      )
      break
    case MedicalEventDocumentType.ADDING:
      serializeMedicalEventFiltersToUrl([{ name: UrlQueryParams.ADDING, value: 'true' }])
      break
  }
}

function* onSelectDocumentWatcher() {
  yield takeEvery(MedicalEventsUiActionTypes.SELECT_DOCUMENT, selectDocumentWorker)
}

function* onAskToClearFilterWorker() {
  yield put(medicalEventUiActions.clearFilters())
}

function* onAskToClearFilterWatcher() {
  yield takeEvery(MedicalEventsUiActionTypes.ASK_TO_CLEAR_FILTERS, onAskToClearFilterWorker)
}

function* clearStateWorker() {
  yield put(medicalEventUiActions.useId(null))
  yield put(medicalEventDocumentInstancesActions.useId(null))
  yield put(medicalEventContentUiActions.useId(null))
  yield put(medicalEventUiActions.clearFilters())
}

function* clearStateWatcher() {
  yield takeEvery(MedicalEventsUiActionTypes.CLEAR, clearStateWorker)
}

// eslint-disable-next-line require-yield
function* onStoreSetItemDetailsWorker({
  item,
}: ReturnType<typeof medicalEventsActions.actions.storeSetItemDetails>) {
  const inUsePatientId = getCurrentPatientId()
  if (inUsePatientId === null) {
    return
  }
  if (item.patientId !== inUsePatientId) {
    // Redirection vers la page d'erreur
    throw new Error(
      `Impossible d'acceder à l'événement medical id(${item.id}) pour le patient id(${item.patientId})`,
    )
  }
}

function* onStoreSetItemDetailsWatcher() {
  yield takeEvery(medicalEventsActions.types.STORE_SET_ITEM_DETAILS, onStoreSetItemDetailsWorker)
}

export const medicalEventsSagas = {
  ...paginationSagas,
  onSelectDocumentWatcher,
  onAskToClearFilterWatcher,
  onStoreSetItemDetailsWatcher,
  clearStateWatcher,
}
