import React, { FunctionComponent, RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { Resizable } from 're-resizable'

import useIsomorphicLayoutEffect from '../../hooks/useIsomorphicLayoutEffect.hook'
import { Corner } from './Corner'

import { ImageContainer, BackgroundImage } from './Image.styled'
import { MINIMAL_SIZE, ImageProps, DEFAULT_MAX_WIDTH } from './Image.model'
import { fetchImageForFarteEditor, constraintSize } from './Image.utils'
import { useContext } from 'react'
import { AuthContext } from '../../core/AuthContext'
import { NumericResize } from './NumericResize'

function useDimensions(ref: RefObject<HTMLElement>): RefObject<DOMRect | null> {
  const dimensions = useRef<DOMRect | null>(null)
  useIsomorphicLayoutEffect(() => {
    if (!ref.current || dimensions.current !== null) {
      return
    }
    const observer = new ResizeObserver(() => {
      if (ref.current) {
        dimensions.current = ref.current.getBoundingClientRect()
      }
    })
    observer.observe(ref.current)
    return () => observer.disconnect()
  }, [ref])
  return dimensions
}

const Image: FunctionComponent<ImageProps> = ({
  url,
  isFocused,
  readOnly,
  editing,
  align,
  size,
  renderAttributes,
  setSize,
}) => {
  const [innerSize, setInnerSize] = useState(MINIMAL_SIZE)
  const [loaded, setLoaded] = useState(false)
  const [numericResizing, setNumericResizing] = useState(false)
  const { token, usurpedUser } = useContext(AuthContext)
  const [base64Image, setBase64Image] = useState('')

  const div = useRef<HTMLDivElement>(null)
  const dimensions = useDimensions(div)

  useEffect(() => {
    ;(async () => {
      const image = await fetchImageForFarteEditor(url, token, usurpedUser)
      setBase64Image(image.content)
    })()
  }, [url])

  useEffect(() => {
    setLoaded(false)
    setInnerSize(MINIMAL_SIZE)
    const image = new window.Image()
    image.src = base64Image
    image
      .decode()
      .then(() => {
        const MAX_WIDTH = dimensions.current ? dimensions.current.width : DEFAULT_MAX_WIDTH
        const { width, height } = image
        setInnerSize(size ? size : constraintSize({ width, height }, MAX_WIDTH))
        setLoaded(true)
      })
      .catch((e) => {
        console.error(e)
      })
  }, [base64Image])

  useEffect(() => {
    if (innerSize.width > MINIMAL_SIZE.width && innerSize.height > MINIMAL_SIZE.height) {
      const newSize = constraintSize(innerSize)
      setSize(newSize)
    }
  }, [innerSize])

  const smallLayout = useMemo(() => innerSize.width < 40 && innerSize.height < 40, [innerSize])

  const onContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault()
    setNumericResizing(!numericResizing)
  }

  return editing && !readOnly ? (
    <div ref={div}>
      <ImageContainer
        align={align}
        isFocused={isFocused}
        onContextMenu={onContextMenu}
        title={"Clic droit sur l'image pour la redimensionner avec précision"}
        {...renderAttributes}
      >
        {loaded ? (
          <>
            <Resizable
              size={innerSize}
              lockAspectRatio={true}
              bounds="parent"
              maxWidth={DEFAULT_MAX_WIDTH}
              minWidth={MINIMAL_SIZE.width}
              onResizeStop={(_event, _direction, _element, delta) => {
                setInnerSize({
                  width: innerSize.width + delta.width,
                  height: innerSize.height + delta.height,
                })
              }}
            >
              <BackgroundImage
                url={base64Image}
                selected={isFocused || numericResizing}
                {...renderAttributes}
              />
              {(isFocused || numericResizing) && (
                <>
                  <Corner small={smallLayout} top right />
                  <Corner small={smallLayout} top left />
                  <Corner small={smallLayout} bottom right />
                  <Corner small={smallLayout} bottom left />
                </>
              )}
              {numericResizing && (
                <NumericResize size={innerSize} url={url} setSize={setInnerSize} />
              )}
            </Resizable>
          </>
        ) : null}
      </ImageContainer>
    </div>
  ) : (
    <ImageContainer align={align} isFocused={isFocused} {...renderAttributes}>
      <img
        src={readOnly ? base64Image : url}
        width={size ? size.width : 'auto'}
        height={size ? size.height : 'auto'}
      />
    </ImageContainer>
  )
}

export default Image
