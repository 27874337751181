import type { CoreScaleOptions, Scale } from 'chart.js'
import { createElement } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { AudiogramPoint } from '../components/audiometry'
import {
  AUDIOGRAM_DASHED_MODES,
  AUDIOGRAM_SOLID_MODES,
} from '../model/enums/audiometry/Audiometry.enums'
import {
  AudiogramIconOptions,
  AudiogramStrokeMode,
  AudiometerInstance,
  EarSide,
  EarSidePointImageDict,
  SimplePoint,
  SpeechAudiogram,
  ToneAudiogram,
  Tympanogram,
} from '../model/types/Audiometry.types'
import colors from '../styles/variables/_colors_v3.scss'
import { reshadeRgbColor } from './colors.utils'

const getAudiometerIconNode = (
  options: AudiogramIconOptions,
  generateNodeFromSvgSrc: (svgSrc: string) => object,
) => {
  const component = createElement(AudiogramPoint, options)
  const staticComponent = renderToStaticMarkup(component)
  const svgSrc = `data:image/svg+xml,${staticComponent}`

  return generateNodeFromSvgSrc(svgSrc)
}

const generateAudiometerEarSideImageNodes = (
  side: EarSide,
  hover: boolean,
  generateNodeFromSvgSrc: (svgSrc: string) => object,
) => {
  const variables = getEarSideVariables(side)

  const allModes: Array<AudiogramStrokeMode> = [...AUDIOGRAM_DASHED_MODES, ...AUDIOGRAM_SOLID_MODES]

  const color = hover ? variables.hoverColor : variables.color

  return allModes.reduce<EarSidePointImageDict>(
    (dict, mode) => ({
      ...dict,
      [mode]: {
        heard: getAudiometerIconNode({ color, mode, side }, generateNodeFromSvgSrc),
        unheard: getAudiometerIconNode(
          { color, mode, side, unheard: true },
          generateNodeFromSvgSrc,
        ),
      },
    }),
    {} as EarSidePointImageDict,
  )
}

export const generateAudiometerImageNodes = (
  hover: boolean,
  generateNodeFromSvgSrc: (svgSrc: string) => object,
): Record<EarSide, EarSidePointImageDict> => {
  return {
    left: generateAudiometerEarSideImageNodes('left', hover, generateNodeFromSvgSrc),
    right: generateAudiometerEarSideImageNodes('right', hover, generateNodeFromSvgSrc),
  }
}

export const getEarSideVariables = (side: EarSide) => {
  const isLeft = side === 'left'

  const color = isLeft ? colors.labelBlue : colors.labelRed
  const hoverColor = reshadeRgbColor(color, 0.2) ?? color

  return {
    shortLabel: isLeft ? 'OG' : 'OD',
    longerLabel: isLeft ? 'Oreille Gauche' : 'Oreille Droite',
    color,
    hoverColor,
  }
}

export const displayRangeLabelEveryInterval =
  (interval: number) => (axis: Scale<CoreScaleOptions>) => {
    axis.ticks.forEach((tick) => {
      if (tick.value % interval !== 0) {
        tick.label = ''
      }
    })
  }

export const sortAudiometryPoints = <Point extends SimplePoint>(points: Array<Point>) => {
  return Array.from(points).sort((a, b) => {
    if (a.x !== b.x) {
      return a.x > b.x ? 1 : -1
    }
    if (a.y !== b.y) {
      return a.y > b.y ? 1 : -1
    }
    return 0
  })
}

const deserializeAudiogram = <T extends Tympanogram | SpeechAudiogram>(audiogram: T): T => ({
  ...audiogram,
  curve: {
    ...audiogram.curve,
    points: sortAudiometryPoints(audiogram.curve.points),
  },
})
const deserializeToneAudiogram = (audiogram: ToneAudiogram): ToneAudiogram => ({
  ...audiogram,
  freeFieldCurve: {
    ...audiogram.freeFieldCurve,
    points: sortAudiometryPoints(audiogram.freeFieldCurve.points),
  },
  boneConductionCurve: {
    ...audiogram.boneConductionCurve,
    points: sortAudiometryPoints(audiogram.boneConductionCurve.points),
  },
})

export const deserializeAudiometerInstance = (
  audiometer: AudiometerInstance,
): AudiometerInstance => {
  return {
    ...audiometer,
    speechAudiogramLeftEar: audiometer.speechAudiogramLeftEar,
    speechAudiogramRightEar: audiometer.speechAudiogramRightEar,
    tympanogramLeftEar: deserializeAudiogram(audiometer.tympanogramLeftEar),
    tympanogramRightEar: deserializeAudiogram(audiometer.tympanogramRightEar),
    toneAudiogramLeftEar: deserializeToneAudiogram(audiometer.toneAudiogramLeftEar),
    toneAudiogramRightEar: deserializeToneAudiogram(audiometer.toneAudiogramRightEar),
  }
}
