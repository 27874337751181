import { RestuxResources } from '../../resources'
import { toUpperSnakeCase } from '../../restux/restux.utilities'

const RESOURCE_PREFIX = `@${toUpperSnakeCase(RestuxResources.medicalEvent)}_DOMAIN`

export const DomainMedicalEventsActionTypes = {
  INITIALIZE_FROM_URL: `${RESOURCE_PREFIX}/INITIALIZE_FROM_URL`,
  INITIALIZE_FILTER_FROM_URL: `${RESOURCE_PREFIX}/INITIALIZE_FILTER_FROM_URL`,
  DOWNLOAD_DOCUMENT_RENDER_HISTORY: `${RESOURCE_PREFIX}/DOWNLOAD_DOCUMENT_RENDER_HISTORY`,
  ADD_DOCUMENT_INSTANCES: `${RESOURCE_PREFIX}/ADD_DOCUMENT_INSTANCES`,
  DELETE_DOCUMENT: `${RESOURCE_PREFIX}/DELETE_DOCUMENT`,
  UPDATE_DOCUMENT_TITLE: `${RESOURCE_PREFIX}/UPDATE_DOCUMENT_TITLE`,
  UPDATE_DOCUMENT_THEN_FETCH_EVENT: `${RESOURCE_PREFIX}/UPDATE_DOCUMENT_THEN_FETCH_EVENT`,
  UPDATE_FILE_THEN_FETCH_EVENT: `${RESOURCE_PREFIX}/UPDATE_FILE_THEN_FETCH_EVENT`,
  DELETE_QUESTIONNAIRE_FROM_SELECTED_DOCUMENT: `${RESOURCE_PREFIX}/DELETE_QUESTIONNAIRE_FROM_SELECTED_DOCUMENT`,
  DELETE_DRUG_FROM_SELECTED_DOCUMENT: `${RESOURCE_PREFIX}/DELETE_DRUG_FROM_SELECTED_DOCUMENT`,
  DELETE_QUOTELINE_FROM_SELECTED_DOCUMENT: `${RESOURCE_PREFIX}/DELETE_QUOTELINE_FROM_SELECTED_DOCUMENT`,
  DELETE_AUDIOMETER_FROM_SELECTED_DOCUMENT: `${RESOURCE_PREFIX}/DELETE_AUDIOMETER_FROM_SELECTED_DOCUMENT`,
  ADD_FILES_TO_MEDICAL_EVENT: `${RESOURCE_PREFIX}/ADD_FILES_TO_MEDICAL_EVENT`,
  SET_ANSWER_VALUE: `${RESOURCE_PREFIX}/SET_ANSWER_VALUE`,
  CLEAR_CONTEXT: `${RESOURCE_PREFIX}/CLEAR_CONTEXT`,
  DUPLICATE_MEDICAL_EVENT_DOCUMENTS: `${RESOURCE_PREFIX}/DUPLICATE_MEDICAL_EVENT_DOCUMENTS`,
  LOCK_MEDICAL_EVENT_DOCUMENTS: `${RESOURCE_PREFIX}/LOCK_MEDICAL_EVENT_DOCUMENTS`,
  PIN_MEDICAL_EVENT_DOCUMENTS: `${RESOURCE_PREFIX}/PIN_MEDICAL_EVENT_DOCUMENTS`,
  CHANGE_OWNER: `${RESOURCE_PREFIX}/CHANGE_OWNER`,
  UPDATE_MEDICAL_EVENT: `${RESOURCE_PREFIX}/UPDATE_MEDICAL_EVENT`,
}

export enum UrlQueryParams {
  DOC_ID = 'docId',
  FILE_ID = 'fileId',
  CONTENT_TYPE = 'contentType',
  QUESTIONNAIRE_ID = 'questionnaireId',
  ACTE_ID = 'acteId',
  AUDIOMETER_ID = 'audiometerId',
  ADDING = 'adding',
  PRESCRIPTION = 'prescriptionId',
  INTERVAL_ID = 'intervalId',
  SENDING = 'sending',
}

interface BaseSaveAnswerBody {
  medicalEventId: number
  documentId: number
  questionId: number
}

interface SaveAnswerValueBody extends BaseSaveAnswerBody {
  value: string
}

interface SaveAnswerValueIdBody extends BaseSaveAnswerBody {
  answerId: number
}

export type SaveAnswerValueBodyType = SaveAnswerValueBody | SaveAnswerValueIdBody
