import { CartesianTickOptions, Chart, ElementOptionsByType } from 'chart.js'
import Annotation from 'chartjs-plugin-annotation'
import colors from '../../../styles/variables/_colors_v3.scss'

Chart.register(Annotation)

export const AUDIOGRAM_DASHED_MODES = ['boneConduction', 'maskedBoneConduction'] as const
export const AUDIOGRAM_SOLID_MODES = [
  'helplessFreeField',
  'maskedHelplessFreeField',
  'helpedFreeField',
  'maskedHelpedFreeField',
] as const
export const AUDIOGRAM_STROKE_MODES = [...AUDIOGRAM_DASHED_MODES, ...AUDIOGRAM_SOLID_MODES] as const

export const AUDIOMETRY_TICKS_OPTIONS: Partial<CartesianTickOptions> = {
  font: {
    family: '"Montserrat", sans-serif',
    weight: 400,
  },
  color: colors.shade4,
}

export const AUDIOMETRY_ELEMENTS_OPTIONS: DeepPartial<ElementOptionsByType<'line'>> = {
  line: {
    borderWidth: 2,
  },
  point: {
    backgroundColor: 'transparent',
    radius: 20,
    borderWidth: 2,
    hoverRadius: 20,
    hoverBorderWidth: 2,
  },
}
