import { css } from '@emotion/react'
import styled from '@emotion/styled'
import colorsv3 from '../../../../styles/variables/_colors_v3.scss'

const Cell = styled.td<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled
      ? ''
      : css`
          cursor: pointer;
          &:hover {
            background-color: ${colorsv3.shade7};
          }
        `}
`

const Svg = styled.svg`
  margin: 0 auto;
  inset: 0 auto;

  width: 35px;
  height: 35px;
`

export const WeberTestCellStyled = {
  Cell,
  Svg,
}
