import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateWeberTest } from '../../../data/audiometry'
import { WeberTestContent } from '../../../model/Audiometry'
import { UserFriendlyError } from '../../../model/Error'
import { refetchVariableDataOnEditor } from '../../../store/renderer'
import { useActionDispatch } from '../../utils'
import { audiometryKeys } from './audiometry.keys'

interface UseUpdateWeberTestParams {
  audiogramId: number
  payload: Partial<WeberTestContent>
}

export const useUpdateWeberTest = () => {
  const queryClient = useQueryClient()

  const refetchVariablesIfEditorIsActive = useActionDispatch(refetchVariableDataOnEditor)

  const mutation = useMutation({
    mutationFn: async ({ audiogramId, payload }: UseUpdateWeberTestParams) => {
      const { data, ok } = await updateWeberTest(audiogramId, payload)

      if (!data || !ok) {
        throw new UserFriendlyError('error', 'La mise à jour du test Weber a échoué')
      }

      return data
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: audiometryKeys.audiometerInstance(data.audiometerId),
      })
      refetchVariablesIfEditorIsActive()
    },
  })

  return mutation
}
