import React, { FC } from 'react'
import { AudiogramComplexPointProps, audiogramPointSize } from '../../AudiogramPoint.model'

export const RectanglePoint: FC<AudiogramComplexPointProps> = ({
  color,
  fill,
  lowerChevron,
  point,
}) => {
  return (
    <svg
      width={audiogramPointSize}
      height={audiogramPointSize}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <g fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
        <rect
          fill={fill ? color : undefined}
          x="10.25"
          y="10.25"
          width="11.5"
          height="11.5"
          rx="1.5"
          ry="1.5"
        />
        {point && <circle id="point" cx="16" cy="16" r="2" fill={color} />}
        {lowerChevron && <polyline id="chevron" points="18,24 21,27 24,24" />}
      </g>
    </svg>
  )
}
