import { Suspense } from 'react'
import { lazyWithRetry } from '../../../misc/lazy.utilities'
import { Loader } from '../../shared'
import { AudiometerInstanceFormProps } from './AudiometerInstanceForm.model'

const LazyAudiometerInstanceForm = lazyWithRetry(() =>
  /* webpackPreload: false, webpackChunkName: "audiometry" */ import(
    './AudiometerInstanceForm.component'
  ).then(({ AudiometerInstanceForm }) => AudiometerInstanceForm),
)

export const AudiometerInstanceForm = (props: AudiometerInstanceFormProps) => (
  <Suspense fallback={<Loader />}>
    <LazyAudiometerInstanceForm {...props} />
  </Suspense>
)
