import { all, call, put, putResolve, select, take, takeEvery } from 'redux-saga/effects'
import { FwTrackingEvent, TrackingService } from '../../../misc/Tracking'
import { DocumentInstance } from '../../../model/DocumentInstance'
import { FileType } from '../../../model/File'
import { MedicalEvent, MedicalEventDocumentType } from '../../../model/MedicalEvent'
import { documentInstancesActions } from '../../cache/documentInstances'
import {
  ApiDocumentInstanceBatchResponse,
  ApiDocumentInstanceCreationResource,
  batchDocumentInstances,
  restuxDocumentInstanceApiConfig,
} from '../../cache/documentInstances/api'
import { filesActions } from '../../cache/files'
import { uploadFile } from '../../cache/files/api'
import { medicalEventsActions } from '../../cache/medicalEvents'
import { addError, addInfo, addResponseError, addValid, addWarning } from '../../message'
import { getVariableData } from '../../renderer'
import { hideBottomPanel } from '../../ui/bottomPanel'
import {
  inUseMedicalEventIdSelector,
  inUseMedicalEventSelector,
  medicalEventUiActions,
  selectedDocumentQuestionnairesSelector,
  selectedMedicalEventDocumentSelector,
} from '../../ui/medicalEvents'
import {
  medicalEventContentUiActions,
  MedicalEventContentUiActionTypes,
  SelectedContentType,
} from '../../ui/medicalEvents/medicalEventContent'
import { domainDocumentInstancesActions } from '../documentInstances'
import { medicalEventDomainActions } from './medicalEvents.actions'
import { DomainMedicalEventsActionTypes, UrlQueryParams } from './medicalEvents.model'
import {
  saveChoiceAnswerValue,
  saveChoicesAnswerValues,
  saveSimpleAnswerValue,
} from './questionsAnswerer'
import {
  inUseMedicalEventDocumentIdSelector,
  medicalEventDocumentInstancesActions,
} from '../../ui/medicalEvents/medicalEventDocumentInstances'
import { QuestionType } from '../../../model/Questionnaire'
import {
  documentAnswerValuesDomainActions,
  DocumentAnswerValuesDomainActionsTypes,
} from '../documentAnswerValues'
import { isUploadableToServer } from '../../../misc/files.utilities'
import { generateIdentifier } from '../../restux/restux.utilities'
import { RESTUX_IDENTIFIER } from '../../restux.identifier'
import { ApiResponse } from 'apisauce'
import { inUseMedicalEventDocumentTemplatesSuggestionSelector } from '../../ui/medicalEvents/medicalEventDocumentTemplatesSuggestion'
import { duplicateMedicalEventDocuments } from '../../cache/medicalEvents/api'
import { computePrescriptionVariableId } from '../../../misc/drug.utilities'
import { changeMedicalEventOwner, downloadRenderHistoryFile, lockMedicalEvent } from './api'
import { AnyAction } from 'redux'
import { isSuccessfulApiResponse } from '../../restux/cache/restuxCacheSagas.factory'
import { queryClient } from '../../../App'
import { patientImportantInformationsKeys } from '../../../hooks/queries/patientImportantInformations/patientImportantInformations.keys'
import { importantInformationsKeys } from '../../../hooks/queries/importantInformations/importantInformations.keys'
import { ImportantInformation } from '../../../model/ImportantInformations'
import { getCurrentPatient, getCurrentPatientId } from '../../../misc/currentPatient.utilities'
import { downloadBlob } from '../../../misc/blob.utilities'
import { extractFileNameFromHeader } from '../../../misc/extractFilename.utilities'
import { medicalEventsKeys } from '../../../hooks/queries/medicalEvents/medicalEvents.keys'
import { patientStatisticsKeys } from '../../../hooks/queries/patientStatistics/patientStatistics.keys'
import { VariableKindPrefix } from '@follow/cdk'
import { fileKeys } from '../../../hooks/queries/files/files.keys'
import { documentKeys } from '../../../hooks/queries/documents/documents.keys'
import { patientManualPrescriptionsKeys } from '../../../hooks/queries/patientManualPrescriptions'
import { isDefined } from '../../../misc/functions.utilities'
import { patientTreatmentsKeys } from '../../../hooks/queries/patientTreatments/patientTreatments.keys'
import { parseFiniteNumber } from '../../../misc/math.utilities'

function parseContentQueryParams(search: string) {
  const urlQuery = new URLSearchParams(search)
  const contentTypeStr = urlQuery.get(UrlQueryParams.CONTENT_TYPE)
  const questionnaireIdStr = urlQuery.get(UrlQueryParams.QUESTIONNAIRE_ID)
  const prescriptionId = urlQuery.get(UrlQueryParams.PRESCRIPTION)
  const acteId = urlQuery.get(UrlQueryParams.ACTE_ID)
  const audiometerId = urlQuery.get(UrlQueryParams.AUDIOMETER_ID)
  const parsedAudiometerId = parseFiniteNumber(audiometerId ?? '')
  const adding = !!urlQuery.get(UrlQueryParams.ADDING)
  const contentType: SelectedContentType | undefined = contentTypeStr
    ? SelectedContentType[contentTypeStr.toUpperCase()]
    : undefined

  const questionnaireId = questionnaireIdStr ? parseInt(questionnaireIdStr, 10) : null

  return {
    questionnaireId,
    contentType,
    adding,
    prescriptionId,
    acteId,
    audiometerId: parsedAudiometerId,
  }
}

/**
 * Initialisation des parametres de filtrage de la page medicalEvent en fonction des query params renseigne dans l'url
 */
function* initializeDocumentFromUrlWorker({
  location,
  medicalEvent,
}: ReturnType<typeof medicalEventDomainActions.initializeFromUrl>) {
  const { docId, fileId, adding, sending } = parseDocumentQueryParams(location.search)

  if (docId) {
    yield put(
      medicalEventUiActions.selectMedicalEventDocument(
        {
          medicalEventDocumentType: MedicalEventDocumentType.FW_DOCUMENT,
          id: docId,
        },
        true,
        sending,
      ),
    )
  } else if (fileId) {
    yield put(
      medicalEventUiActions.selectMedicalEventDocument(
        {
          medicalEventDocumentType: MedicalEventDocumentType.FILE,
          id: fileId,
        },
        true,
        sending,
      ),
    )
  } else if (medicalEvent.documents.length > 0 && adding === null && !docId && !fileId) {
    // On n'affiche les notes que si il y a au moins un document dans la consultation
    yield put(
      medicalEventUiActions.selectMedicalEventDocument(
        {
          medicalEventDocumentType: MedicalEventDocumentType.OBSERVATIONS,
        },
        true,
        sending,
      ),
    )
  }
}

function* onInitializeDocumentFromUrlWatcher() {
  yield takeEvery(
    DomainMedicalEventsActionTypes.INITIALIZE_FROM_URL,
    initializeDocumentFromUrlWorker,
  )
}

// Population du store via les query param si nécessaire
// Query params can be ?contentType={contentType}&questionnaireId={questionnaireId}&adding=true
function* initializeFilterContentFromUrlWorker() {
  const { contentType, questionnaireId, adding, prescriptionId, acteId, audiometerId } =
    parseContentQueryParams(document.location.search)

  if (contentType === SelectedContentType.QUESTIONNAIRE && questionnaireId !== null) {
    yield put(
      medicalEventContentUiActions.selectMedicalEventContent({
        type: contentType,
        questionnaireId,
      }),
    )
  } else if (contentType === SelectedContentType.PRESCRIPTION && prescriptionId !== null) {
    yield put(
      medicalEventContentUiActions.selectMedicalEventContent({
        type: contentType,
        prescriptionId: prescriptionId,
      }),
    )
  } else if (contentType === SelectedContentType.ACTE && acteId !== null) {
    yield put(
      medicalEventContentUiActions.selectMedicalEventContent({
        type: contentType,
        acteId: acteId,
      }),
    )
  } else if (contentType === SelectedContentType.AUDIOMETER && audiometerId !== null) {
    yield put(
      medicalEventContentUiActions.selectMedicalEventContent({
        type: contentType,
        audiometerId,
      }),
    )
  } else if (
    contentType &&
    contentType !== SelectedContentType.QUESTIONNAIRE &&
    contentType !== SelectedContentType.PRESCRIPTION &&
    contentType !== SelectedContentType.AUDIOMETER &&
    contentType !== SelectedContentType.ACTE
  ) {
    yield put(medicalEventContentUiActions.selectMedicalEventContent({ type: contentType }))
  } else if (adding) {
    yield put(
      medicalEventUiActions.selectMedicalEventDocument({
        medicalEventDocumentType: MedicalEventDocumentType.ADDING,
      }),
    )
  } else {
    medicalEventContentUiActions.selectMedicalEventContent({ type: SelectedContentType.EDITOR })
  }
}

function* initializeFilterContentFromUrlWatcher() {
  yield takeEvery(
    DomainMedicalEventsActionTypes.INITIALIZE_FILTER_FROM_URL,
    initializeFilterContentFromUrlWorker,
  )
}

/**
 * Suppression du document selectionné de la consultation
 * Gere la suppression des "Bilan", "CRO", "Document" et "Fichier"
 */
function* deleteDocumentWorker({
  id,
  documentType,
}: ReturnType<typeof medicalEventDomainActions.deleteDocument>) {
  const patientId = getCurrentPatientId()
  const taskIdentifier = generateIdentifier(RESTUX_IDENTIFIER.deleteDocumentFromMedicalEvent)
  switch (documentType) {
    case MedicalEventDocumentType.FW_DOCUMENT:
      /* *** ANALYTICS *** */
      TrackingService.sendEvent(FwTrackingEvent.DELETE_MEDICAL_EVENT_DOCUMENT)
      yield put(documentInstancesActions.actions.apiDeleteItem(id, { identifier: taskIdentifier }))
      yield fetchMedicalEventAfterContentDeletion(
        taskIdentifier,
        documentInstancesActions.types.STORE_DELETE_ITEM,
      )
      patientId
        ? queryClient.invalidateQueries({
            queryKey: patientImportantInformationsKeys.listAll(patientId),
          })
        : queryClient.invalidateQueries({ queryKey: patientImportantInformationsKeys.lists })
      break
    case MedicalEventDocumentType.FILE:
      /* *** ANALYTICS *** */
      TrackingService.sendEvent(FwTrackingEvent.DELETE_MEDICAL_EVENT_FILE)
      yield put(filesActions.actions.apiDeleteItem(id, { identifier: taskIdentifier }))
      yield fetchMedicalEventAfterContentDeletion(
        taskIdentifier,
        filesActions.types.STORE_DELETE_ITEM,
      )
      break
  }
  queryClient.invalidateQueries({ queryKey: medicalEventsKeys.lists })

  if (isDefined(patientId)) {
    queryClient.invalidateQueries({ queryKey: patientManualPrescriptionsKeys.list(patientId) })
    queryClient.invalidateQueries({ queryKey: patientTreatmentsKeys.lists(patientId) })
    queryClient.invalidateQueries({ queryKey: patientStatisticsKeys.detail(patientId) })
  }
}

function* fetchMedicalEventAfterContentDeletion(taskIdentifier: string, waitForAction: string) {
  const currentMedicalEvent: ReturnType<typeof inUseMedicalEventSelector> =
    yield select(inUseMedicalEventSelector)
  if (!currentMedicalEvent) {
    console.warn('No inUse medicalEvent to refetch ')
    return
  }
  const { identifier } = yield take(waitForAction)
  if (identifier === taskIdentifier) {
    yield put(medicalEventsActions.actions.apiGetItemDetails(currentMedicalEvent.id))
    yield put(
      medicalEventUiActions.selectMedicalEventDocument({
        medicalEventDocumentType: MedicalEventDocumentType.ADDING,
      }),
    )
  }
}

function* onDeleteDocumentWatcher() {
  yield takeEvery(DomainMedicalEventsActionTypes.DELETE_DOCUMENT, deleteDocumentWorker)
}

function* documentTitleUpdateWorker({
  id,
  title,
  eventDocumentType,
  documentType,
}: ReturnType<typeof medicalEventDomainActions.updateDocumentTitle>) {
  const taskIdentifier = generateIdentifier(RESTUX_IDENTIFIER.updateDocumentTitleFromMedicalEvent)
  switch (eventDocumentType) {
    case MedicalEventDocumentType.FW_DOCUMENT:
      /* *** ANALYTICS *** */
      TrackingService.sendEvent(FwTrackingEvent.UPDATE_MEDICAL_EVENT_DOCUMENT_TITLE)
      yield put(
        documentInstancesActions.actions.apiUpdateItem(
          id,
          { title, type: documentType },
          { identifier: taskIdentifier },
        ),
      )
      yield fetchMedicalEventAfterContentUpdate(
        taskIdentifier,
        documentInstancesActions.types.STORE_SET_ITEM_DETAILS,
      )
      queryClient.invalidateQueries({ queryKey: documentKeys.detail(id) })

      break
    case MedicalEventDocumentType.FILE:
      /* *** ANALYTICS *** */
      TrackingService.sendEvent(FwTrackingEvent.UPDATE_MEDICAL_EVENT_FILE_TITLE)
      yield put(
        filesActions.actions.apiUpdateItem(
          id,
          { visibleName: title },
          { identifier: taskIdentifier },
        ),
      )
      yield fetchMedicalEventAfterContentUpdate(
        taskIdentifier,
        filesActions.types.STORE_SET_ITEM_DETAILS,
      )
      queryClient.invalidateQueries({ queryKey: fileKeys.detail(id) })
      break
  }
  queryClient.invalidateQueries({ queryKey: medicalEventsKeys.lists })
}

function* onDocumentTitleUpdateWatcher() {
  yield takeEvery(DomainMedicalEventsActionTypes.UPDATE_DOCUMENT_TITLE, documentTitleUpdateWorker)
}

function* updateDocumentThenFetchEventWorker({
  id,
  document,
}: ReturnType<typeof medicalEventDomainActions.updateDocumentThenFetchEvent>) {
  const identifier = generateIdentifier(RESTUX_IDENTIFIER.documentInstanceEdition)
  yield put(documentInstancesActions.actions.apiUpdateItem(id, document, { identifier }))
  yield fetchMedicalEventAfterContentUpdate(
    identifier,
    documentInstancesActions.types.STORE_SET_ITEM_DETAILS,
  )
}

function* updateDocumentThenFetchEventWatcher() {
  yield takeEvery(
    DomainMedicalEventsActionTypes.UPDATE_DOCUMENT_THEN_FETCH_EVENT,
    updateDocumentThenFetchEventWorker,
  )
}

function* updateFileThenFetchEventWorker({
  id,
  file,
}: ReturnType<typeof medicalEventDomainActions.updateFileThenFetchEvent>) {
  const identifier = generateIdentifier(RESTUX_IDENTIFIER.fileEdition)

  yield put(filesActions.actions.apiUpdateItem(id, file, { identifier }))
  yield fetchMedicalEventAfterContentUpdate(identifier, filesActions.types.STORE_SET_ITEM_DETAILS)
}

function* updateFileThenFetchEventWatcher() {
  yield takeEvery(
    DomainMedicalEventsActionTypes.UPDATE_FILE_THEN_FETCH_EVENT,
    updateFileThenFetchEventWorker,
  )
}

function* fetchMedicalEventAfterContentUpdate(taskIdentifier: string, waitForAction: string) {
  const currentMedicalEvent: ReturnType<typeof inUseMedicalEventSelector> =
    yield select(inUseMedicalEventSelector)
  if (!currentMedicalEvent) {
    console.warn('No inUse medicalEvent to refetch ')
    return
  }
  const { identifier } = yield take(waitForAction)
  if (identifier === taskIdentifier) {
    yield put(medicalEventsActions.actions.apiGetItemDetails(currentMedicalEvent.id))
    yield put(getVariableData())
  }
}

/** *** UTILITIES *** */

function parseDocumentQueryParams(search: string) {
  const urlQuery = new URLSearchParams(search)
  const docIdStr = urlQuery.get(UrlQueryParams.DOC_ID)
  const fileIdStr = urlQuery.get(UrlQueryParams.FILE_ID)
  const adding = urlQuery.get(UrlQueryParams.ADDING)
  const sending = urlQuery.get(UrlQueryParams.SENDING)

  const docId = docIdStr ? parseInt(docIdStr, 10) : null
  const fileId = !docId && fileIdStr ? parseInt(fileIdStr, 10) : null

  return { docId, fileId, adding, sending }
}

/**
 *  Suppression d'un questionnaire identifié via son "id" du document selectionné
 *  Il est nécessaire que le document soit corretement selectionné dans les filtres de la page "medical event"
 */
function* deleteQuestionnaireFromSelectedEventWorker({
  questionnaireId,
}: ReturnType<typeof medicalEventDomainActions.deleteQuestionnaireFromSelectedDocument>) {
  const selectedDocument: ReturnType<typeof selectedMedicalEventDocumentSelector> = yield select(
    selectedMedicalEventDocumentSelector,
  )
  const selectedQuestionnaires: ReturnType<typeof selectedDocumentQuestionnairesSelector> =
    yield select(selectedDocumentQuestionnairesSelector)
  if (
    selectedDocument !== null &&
    selectedDocument.type === MedicalEventDocumentType.FW_DOCUMENT &&
    selectedQuestionnaires !== null &&
    selectedDocument.item.type === 'farte'
  ) {
    /* *** ANALYTICS *** */
    TrackingService.sendEvent(FwTrackingEvent.DELETE_MEDICAL_EVENT_QUESTIONNAIRE)

    yield putResolve(
      domainDocumentInstancesActions.removeVariable(
        selectedDocument.item,
        `questionnaire_${questionnaireId}`,
      ),
    )
    const patientId = getCurrentPatientId()
    patientId &&
      queryClient.invalidateQueries({ queryKey: patientStatisticsKeys.detail(patientId) })

    const newQuestionnaireIds = selectedQuestionnaires
      .map(({ id }) => id)
      .filter((id) => id !== questionnaireId)

    if (newQuestionnaireIds.length > 0) {
      yield put(medicalEventContentUiActions.useId(newQuestionnaireIds[0]))
      yield put(
        medicalEventContentUiActions.selectMedicalEventContent({
          type: SelectedContentType.QUESTIONNAIRE,
          questionnaireId: newQuestionnaireIds[0],
        }),
      )
    } else {
      yield put(
        medicalEventContentUiActions.selectMedicalEventContent({
          type: SelectedContentType.EDITOR,
        }),
      )
      yield put(medicalEventContentUiActions.useId(null))
    }
  }
}

function* onDeleteQuestionnaireFromSelectedEventWatcher() {
  yield takeEvery(
    DomainMedicalEventsActionTypes.DELETE_QUESTIONNAIRE_FROM_SELECTED_DOCUMENT,
    deleteQuestionnaireFromSelectedEventWorker,
  )
}

function* deleteDrugFromSelectedDocumentWorker({
  prescription,
}: ReturnType<typeof medicalEventDomainActions.deleteDrugFromSelectedDocument>) {
  const selectedDocument: ReturnType<typeof selectedMedicalEventDocumentSelector> = yield select(
    selectedMedicalEventDocumentSelector,
  )
  if (
    selectedDocument?.type === MedicalEventDocumentType.FW_DOCUMENT &&
    selectedDocument.item?.type === 'farte'
  ) {
    yield putResolve(
      domainDocumentInstancesActions.removeVariable(
        selectedDocument.item,
        computePrescriptionVariableId(prescription.prescriptionVariableUuid),
      ),
    )

    yield put(
      medicalEventContentUiActions.selectMedicalEventContent({
        type: SelectedContentType.EDITOR,
      }),
    )
  }
}

function* deleteDrugFromSelectedDocumentWatcher() {
  yield takeEvery(
    DomainMedicalEventsActionTypes.DELETE_DRUG_FROM_SELECTED_DOCUMENT,
    deleteDrugFromSelectedDocumentWorker,
  )
}

function* deleteQuoteLineFromSelectedDocumentWorker({
  quoteLineUuid,
}: ReturnType<typeof medicalEventDomainActions.deleteQuoteLineFromSelectedDocument>) {
  const selectedDocument: ReturnType<typeof selectedMedicalEventDocumentSelector> = yield select(
    selectedMedicalEventDocumentSelector,
  )
  if (
    selectedDocument?.type === MedicalEventDocumentType.FW_DOCUMENT &&
    selectedDocument.item?.type === 'farte'
  ) {
    yield putResolve(
      domainDocumentInstancesActions.removeVariable(
        selectedDocument.item,
        `${VariableKindPrefix.QUOTE_LINE}_${quoteLineUuid}`,
      ),
    )

    yield put(
      medicalEventContentUiActions.selectMedicalEventContent({
        type: SelectedContentType.EDITOR,
      }),
    )
  }
}

function* deleteQuoteLineFromSelectedDocumentWatcher() {
  yield takeEvery(
    DomainMedicalEventsActionTypes.DELETE_QUOTELINE_FROM_SELECTED_DOCUMENT,
    deleteQuoteLineFromSelectedDocumentWorker,
  )
}

function* deleteAudiometerFromDocumentWorker({
  audiometerId,
}: ReturnType<typeof medicalEventDomainActions.deleteAudiometerFromSelectedDocument>) {
  const selectedDocument: ReturnType<typeof selectedMedicalEventDocumentSelector> = yield select(
    selectedMedicalEventDocumentSelector,
  )
  if (
    selectedDocument?.type === MedicalEventDocumentType.FW_DOCUMENT &&
    selectedDocument.item?.type === 'farte'
  ) {
    const variableId = `${VariableKindPrefix.AUDIOMETER}_${audiometerId}`

    yield putResolve(
      domainDocumentInstancesActions.removeVariable(selectedDocument.item, variableId),
    )

    yield put(
      medicalEventContentUiActions.selectMedicalEventContent({
        type: SelectedContentType.EDITOR,
      }),
    )
  }
}

function* deleteAudiometerFromDocumentWatcher() {
  yield takeEvery(
    DomainMedicalEventsActionTypes.DELETE_AUDIOMETER_FROM_SELECTED_DOCUMENT,
    deleteAudiometerFromDocumentWorker,
  )
}

function* onDownloadDocumentRenderHistoryWorker({
  renderHistory,
}: ReturnType<typeof medicalEventDomainActions.downloadDocumentRenderHistory>) {
  yield put(addValid('Archive en cours de génération'))
  try {
    const response: ApiResponse<Blob> = yield call(downloadRenderHistoryFile, renderHistory.id)
    if (!response.ok || !response.data) {
      throw new Error('Failed to fetch history entry blob')
    }
    const extractedFileName = extractFileNameFromHeader(response.headers) || 'Document'
    const fileName = `Archive--${extractedFileName}`

    downloadBlob(response.data, fileName)
  } catch (error) {
    yield put(addError('Erreur de téléchargement', `Impossible de générer l'archive demandée`))
  }
}

function* onDownloadDocumentRenderHistoryWatcher() {
  yield takeEvery(
    DomainMedicalEventsActionTypes.DOWNLOAD_DOCUMENT_RENDER_HISTORY,
    onDownloadDocumentRenderHistoryWorker,
  )
}

function* addDocumentInstancesToMedicalEventWorker({
  documentTemplates,
  context,
}: ReturnType<typeof medicalEventDomainActions.addDocumentInstances>) {
  const medicalEvent = yield select(inUseMedicalEventSelector)

  if (!medicalEvent) return

  const currentPatient = getCurrentPatient()
  // Les contacts du patient sont ajoutés au document lors de l'instanciation
  const contacts = currentPatient === null ? [] : [...currentPatient.contacts]

  const documentInstancesToCreate: Array<ApiDocumentInstanceCreationResource> =
    documentTemplates.map(({ id, title }) => ({
      contacts,
      documentTemplateId: id,
      title,
      medicalEventId: medicalEvent.id,
    }))

  yield put(hideBottomPanel())
  yield put(addInfo('Ajout des document', `Ajout des documents à l'évenement médical en cours`))

  const response: ApiResponse<ApiDocumentInstanceBatchResponse> = yield call(
    batchDocumentInstances,
    `${restuxDocumentInstanceApiConfig.url}/batch`,
    documentInstancesToCreate,
  )

  if (!response.ok || !response.data) {
    yield put(addResponseError(response))
    return
  }

  // Update du medicalEvent
  yield put(medicalEventsActions.actions.apiGetItemDetails(medicalEvent.id))
  queryClient.invalidateQueries({ queryKey: medicalEventsKeys.lists })
  queryClient.invalidateQueries({ queryKey: patientStatisticsKeys.detail(medicalEvent.patientId) })

  if (currentPatient) {
    queryClient.invalidateQueries({
      queryKey: patientManualPrescriptionsKeys.list(currentPatient.id),
    })
  }

  const toBeSelectedDocument = response.data[0]
  yield put(medicalEventDocumentInstancesActions.useId(null))
  yield put(
    medicalEventUiActions.selectMedicalEventDocument({
      medicalEventDocumentType: MedicalEventDocumentType.FW_DOCUMENT,
      id: toBeSelectedDocument.id,
      resetSelectedQuestionnaire: true,
    }),
  )

  const params: Record<string, string | number> = {
    ...context,
    count: documentInstancesToCreate.length,
  }

  const suggestion: ReturnType<typeof inUseMedicalEventDocumentTemplatesSuggestionSelector> =
    yield select(inUseMedicalEventDocumentTemplatesSuggestionSelector)
  if (suggestion) {
    documentTemplates.forEach(({ id: docId }) => {
      const suggestionIndex = suggestion.documentTemplates.findIndex(
        (documentTemplate) => documentTemplate.id === docId,
      )
      const wasSuggested = context.origin === 'suggestion' || suggestionIndex > -1
      TrackingService.sendEvent(FwTrackingEvent.INSERT_MEDICAL_EVENT_DOCUMENT, {
        ...params,
        docId,
        wasSuggested,
        suggestionIndex,
      })
    })
  } else {
    // General Analytics
    documentTemplates.forEach(({ id: docId }) => {
      TrackingService.sendEvent(FwTrackingEvent.INSERT_MEDICAL_EVENT_DOCUMENT, {
        ...params,
        docId,
      })
    })
  }
}

function* onAddDocumentInstancesToMedicalEventWatcher() {
  yield takeEvery(
    DomainMedicalEventsActionTypes.ADD_DOCUMENT_INSTANCES,
    addDocumentInstancesToMedicalEventWorker,
  )
}

function* addFilesToMedicalEventWorker({
  files,
}: ReturnType<typeof medicalEventDomainActions.addFilesToMedicalEvent>) {
  const inUseMedicalEvent: ReturnType<typeof inUseMedicalEventSelector> =
    yield select(inUseMedicalEventSelector)
  if (!inUseMedicalEvent) {
    throw new Error(`Can't add a file to an unselected exam`)
  }

  const uploadable = files.filter(isUploadableToServer)
  const unuploadable = files.length - uploadable.length
  if (unuploadable > 0) {
    const wording =
      unuploadable === 1
        ? `${unuploadable} fichier n'a pas pu être uploadé car sa taille est supérieur à 50Mo`
        : `${unuploadable} fichiers n'ont pas pu être uploadé car leur taille est supérieur à 50Mo`
    yield put(addWarning('Taille de fichier non supportée', wording))
  }

  yield put(medicalEventUiActions.hideAddFileDropzone())

  if (uploadable.length === 0) {
    return
  }
  const plural = uploadable.length === 1 ? 'votre fichier' : 'vos fichiers'
  yield put(addInfo(`Upload de ${plural} en cours...`))
  const responses = yield all(
    uploadable.map((file) => call(uploadFile, file, FileType.MEDICAL_EVENT, inUseMedicalEvent.id)),
  )
  const responseError = responses.find((response) => !response.ok)
  if (!!responseError) {
    yield put(addResponseError(responseError))
    return
  }
  yield put(addValid(`Upload de ${plural} terminé`))

  yield put(medicalEventsActions.actions.apiGetItemDetails(inUseMedicalEvent.id))
  queryClient.invalidateQueries({ queryKey: medicalEventsKeys.lists })

  const displayedMedicalEventId: ReturnType<typeof inUseMedicalEventIdSelector> =
    yield select(inUseMedicalEventSelector)

  // Lorsque l'on upload un fichier avec une connexion lente, il est possible que l'utilisateur ait quitté le médical event avant la fin de l'upload
  // Dans ce cas précis, il ne faut pas changer la selection du document d'un potentiel autre medical event affiché
  if (displayedMedicalEventId === inUseMedicalEvent.id) {
    const toBeSelectedDocument: DocumentInstance = responses[responses.length - 1].data
    yield put(
      medicalEventUiActions.selectMedicalEventDocument({
        medicalEventDocumentType: MedicalEventDocumentType.FILE,
        id: toBeSelectedDocument.id,
      }),
    )
  }
}

function* onAddFilesToMedicalEventWatcher() {
  yield takeEvery(
    DomainMedicalEventsActionTypes.ADD_FILES_TO_MEDICAL_EVENT,
    addFilesToMedicalEventWorker,
  )
}

function* fetchAnswerValuesOnQuestionnaireSelectionWorker({
  filter,
}: ReturnType<typeof medicalEventContentUiActions.selectMedicalEventContent>) {
  if (filter.type === SelectedContentType.EDITOR) {
    return
  }
  const documentId: ReturnType<typeof inUseMedicalEventDocumentIdSelector> = yield select(
    inUseMedicalEventDocumentIdSelector,
  )
  if (!documentId || filter.type === SelectedContentType.PRESCRIPTION) {
    return
  }
  yield put(documentAnswerValuesDomainActions.fetchDocumentAnswerValues(documentId))
}

function* fetchAnswerValuesOnQuestionnaireSelectionWatcher() {
  yield takeEvery(
    MedicalEventContentUiActionTypes.SELECT_CONTENT,
    fetchAnswerValuesOnQuestionnaireSelectionWorker,
  )
}

function* setAnswerValueWorker({
  questionId,
  setAnswerValue,
}: ReturnType<typeof medicalEventDomainActions.setAnswerValue>) {
  const selectedMedicalEvent: ReturnType<typeof inUseMedicalEventSelector> =
    yield select(inUseMedicalEventSelector)

  const selectedMedicalEventDocument: ReturnType<typeof selectedMedicalEventDocumentSelector> =
    yield select(selectedMedicalEventDocumentSelector)

  if (
    !selectedMedicalEvent ||
    !selectedMedicalEventDocument ||
    selectedMedicalEventDocument.type !== MedicalEventDocumentType.FW_DOCUMENT
  ) {
    console.warn(
      `Invalid state. "selectedMedicalEvent" and "selectedMedicalEventDocument" must be defined`,
    )
    return
  }

  const documentId = selectedMedicalEventDocument.item.id

  // cas des questions simples qui ne proposent pas de choix pré-définies
  if (
    QuestionType.Text === setAnswerValue.questionType ||
    QuestionType.Slider === setAnswerValue.questionType ||
    QuestionType.Select === setAnswerValue.questionType ||
    QuestionType.Date === setAnswerValue.questionType
  ) {
    // Mise à jour ou création de la réponse

    yield saveSimpleAnswerValue(
      selectedMedicalEvent.id,
      selectedMedicalEventDocument.item.id,
      questionId,
      setAnswerValue.value,
    )

    // Cas spécifique du QCM
  } else if (QuestionType.QCM === setAnswerValue.questionType) {
    yield saveChoicesAnswerValues(
      selectedMedicalEvent.id,
      selectedMedicalEventDocument.item.id,
      questionId,
      setAnswerValue.value || [],
    )
  } else {
    // Cas des réponses à choix uniques
    yield saveChoiceAnswerValue(
      selectedMedicalEvent.id,
      selectedMedicalEventDocument.item.id,
      questionId,
      setAnswerValue.value,
    )
  }
  // Refetch answer values
  yield put(documentAnswerValuesDomainActions.fetchDocumentAnswerValues(documentId))
  yield put(documentAnswerValuesDomainActions.fetchDocumentQuestionnaireScore(documentId))

  queryClient.invalidateQueries({
    queryKey: patientStatisticsKeys.detail(selectedMedicalEvent.patientId),
  })

  // Refetch des informations importantes si la question est marquée comme importante
  const isImportantQuestion = queryClient
    .getQueryData<ImportantInformation[]>(importantInformationsKeys.listAll)
    ?.some(({ id }) => id === questionId)

  if (isImportantQuestion) {
    queryClient.invalidateQueries({
      queryKey: patientImportantInformationsKeys.listAll(selectedMedicalEvent.patientId),
    })
  }
}

function* setAnswerValueWatcher() {
  yield takeEvery(DomainMedicalEventsActionTypes.SET_ANSWER_VALUE, setAnswerValueWorker)
}

function* clearContextWorker() {
  yield put(filesActions.actions.storeClearCache())
  yield put(medicalEventUiActions.clearState())
}

function* clearContextWatcher() {
  yield takeEvery(DomainMedicalEventsActionTypes.CLEAR_CONTEXT, clearContextWorker)
}

function* manageAnswerValuesErrorWorker({
  isError,
}: ReturnType<typeof documentAnswerValuesDomainActions.setAnswerValueError>) {
  if (isError) {
    yield put(
      addError('La sauvegarde de votre dernière réponse à échoué. Le document a été rechargé'),
    )
    const inUseId: ReturnType<typeof inUseMedicalEventIdSelector> = yield select(
      inUseMedicalEventIdSelector,
    )
    if (!inUseId) {
      window.location.reload()
    }
    yield put(medicalEventUiActions.useId(inUseId))
  }
}

function* manageAnswerValuesErrorWatcher() {
  yield takeEvery(DocumentAnswerValuesDomainActionsTypes.SET_ERROR, manageAnswerValuesErrorWorker)
}

function* duplicateMedicalEventDocumentsWorker({
  medicalEventId,
  documentsIds,
}: ReturnType<typeof medicalEventDomainActions.duplicateMedicalEventDocuments>) {
  const { ok } = yield call(duplicateMedicalEventDocuments, medicalEventId, documentsIds)
  if (!ok) {
    yield put(
      addError(
        'Erreur de duplication',
        'Une erreur est survenue lors de la duplication, veuillez réessayer',
      ),
    )
  } else {
    yield put(addValid('Duplication réussie'))
    queryClient.invalidateQueries({ queryKey: medicalEventsKeys.lists })

    const patientId = getCurrentPatientId()
    if (isDefined(patientId)) {
      queryClient.invalidateQueries({ queryKey: patientTreatmentsKeys.lists(patientId) })
    }
  }
}

function* duplicateMedicalEventDocumentsWatcher() {
  yield takeEvery(
    DomainMedicalEventsActionTypes.DUPLICATE_MEDICAL_EVENT_DOCUMENTS,
    duplicateMedicalEventDocumentsWorker,
  )
}

function* lockMedicalEventWorker({
  medicalEvent,
}: ReturnType<typeof medicalEventDomainActions.lockMedicalEvent>) {
  const documentIds = medicalEvent.documents.reduce<Array<number>>(
    (acc, document) => (document.type === 'farte' ? [...acc, document.id] : acc),
    [],
  )

  const resp = yield call(lockMedicalEvent, medicalEvent.id, !medicalEvent.locked, documentIds)

  if (resp.ok) {
    yield put(medicalEventsActions.actions.apiGetItemDetails(medicalEvent.id))
    yield put(
      medicalEventUiActions.selectMedicalEventDocument({
        medicalEventDocumentType: MedicalEventDocumentType.OBSERVATIONS,
      }),
    )
    queryClient.invalidateQueries({ queryKey: medicalEventsKeys.lists })
  }
}

function* lockMedicalEventWatcher() {
  yield takeEvery(
    DomainMedicalEventsActionTypes.LOCK_MEDICAL_EVENT_DOCUMENTS,
    lockMedicalEventWorker,
  )
}

function* pinMedicalEventWorker({
  medicalEvent,
}: ReturnType<typeof medicalEventDomainActions.pinMedicalEvent>) {
  const taskIdentifier = generateIdentifier(RESTUX_IDENTIFIER.pinMedicalEvent)

  yield put(
    medicalEventDomainActions.updateMedicalEvent(
      medicalEvent.id,
      {
        pinned: !medicalEvent.pinned,
      },
      { identifier: taskIdentifier },
    ),
  )
}

function* pinMedicalEventWatcher() {
  yield takeEvery(DomainMedicalEventsActionTypes.PIN_MEDICAL_EVENT_DOCUMENTS, pinMedicalEventWorker)
}

function* changeOwnerWorker({
  medicalEvent,
  newOwnerId,
}: ReturnType<typeof medicalEventDomainActions.changeOwner>) {
  const response: ApiResponse<MedicalEvent> = yield call(
    changeMedicalEventOwner,
    medicalEvent.id,
    newOwnerId,
  )
  if (!isSuccessfulApiResponse(response)) {
    yield put(
      addError(
        'Erreur lors du changement de propriétaire',
        'Une erreur est survenue lors du changement de propriétaire',
      ),
    )
  } else {
    queryClient.invalidateQueries({ queryKey: medicalEventsKeys.lists })
  }
}

function* changeOwnerWatcher() {
  yield takeEvery(DomainMedicalEventsActionTypes.CHANGE_OWNER, changeOwnerWorker)
}

function* updateMedicalEventWorker({
  id,
  payload,
  options,
}: ReturnType<typeof medicalEventDomainActions.updateMedicalEvent>) {
  const taskIdentifier =
    options?.identifier || generateIdentifier(RESTUX_IDENTIFIER.updateMedicalEvent)

  yield put(
    medicalEventsActions.actions.apiUpdateItem(id, payload, {
      ...options,
      identifier: taskIdentifier,
    }),
  )

  yield take(
    (action: AnyAction) =>
      action.identifier === taskIdentifier &&
      action.type === medicalEventsActions.types.STORE_SET_ITEM_DETAILS,
  )

  queryClient.invalidateQueries({ queryKey: medicalEventsKeys.lists })
}

function* updateMedicalEvent() {
  yield takeEvery(DomainMedicalEventsActionTypes.UPDATE_MEDICAL_EVENT, updateMedicalEventWorker)
}

export const medicalEventsDomainSagas = {
  onAddDocumentInstancesToMedicalEventWatcher,
  onAddFilesToMedicalEventWatcher,
  onDownloadDocumentRenderHistoryWatcher,
  onDeleteQuestionnaireFromSelectedEventWatcher,
  deleteDrugFromSelectedDocumentWatcher,
  deleteQuoteLineFromSelectedDocumentWatcher,
  deleteAudiometerFromDocumentWatcher,
  onDeleteDocumentWatcher,
  onDocumentTitleUpdateWatcher,
  onInitializeDocumentFromUrlWatcher,
  initializeFilterContentFromUrlWatcher,
  fetchAnswerValuesOnQuestionnaireSelectionWatcher,
  setAnswerValueWatcher,
  clearContextWatcher,
  manageAnswerValuesErrorWatcher,
  duplicateMedicalEventDocumentsWatcher,
  lockMedicalEventWatcher,
  pinMedicalEventWatcher,
  updateDocumentThenFetchEventWatcher,
  updateFileThenFetchEventWatcher,
  changeOwnerWatcher,
  updateMedicalEvent,
}
