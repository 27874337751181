import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { getAudiometerInstance } from '../../../data/audiometry'
import { AudiometerInstance } from '../../../model/Audiometry'
import { UserFriendlyError } from '../../../model/Error'
import { audiometryKeys } from './audiometry.keys'

interface UseGetAudiometerInstanceParams {
  audiometerId: number
  initialData?: AudiometerInstance
}

export const useGetAudiometerInstance = ({
  audiometerId,
  initialData,
}: UseGetAudiometerInstanceParams) => {
  const queryKey = useMemo(() => audiometryKeys.audiometerInstance(audiometerId), [audiometerId])

  const query = useQuery({
    queryKey,
    queryFn: async ({ signal }) => {
      const { data, ok } = await getAudiometerInstance(audiometerId, signal)

      if (!ok || !data) {
        throw new UserFriendlyError('error', "Erreur lors de la récupération de l'audiométrie")
      }

      return data
    },
    initialData,
  })

  return query
}
