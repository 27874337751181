const rgbRegex = /^rgba?\((?<r>\d+),(?<g>\d+),(?<b>\d+)(?:,[\d\.]+)?\)$/
export const reshadeRgbColor = (color: string, targetOpacity: number) => {
  const trimed = color.replaceAll(' ', '')
  const match = trimed.match(rgbRegex)

  if (!match || !match.groups) {
    return null
  }

  const { r, g, b } = match.groups

  return `rgba(${r},${g},${b},${targetOpacity})`
}
