import { connect } from 'react-redux'

import { RootState } from '../../../store'
import {
  MedicalEventContentStoreProps,
  MedicalEventContentDispatchProps,
} from './MedicalEventContent.model'
import { MedicalEventContent } from './MedicalEventContent.component'
import {
  selectedMedicalEventDocumentSelector,
  isMedicalEventContentLoadingSelector,
  inUseMedicalEventSelector,
  medicalEventUiActions,
} from '../../../store/ui/medicalEvents'
import {
  selectedContentFilterSelector,
  medicalEventContentUiActions,
} from '../../../store/ui/medicalEvents/medicalEventContent'
import {
  inUseMedicalEventQuestionnaireWithAnswerValuesSelector,
  inUseDocumentQuestionListWithAnswerValuesSelector,
  medicalEventDomainActions,
  inUseMedicalEventPrescriptionSelector,
  inUseMedicalEventQuoteLineSelector,
  inUseMedicalEventAudiometerSelector,
} from '../../../store/domain/medicalEvents'
import { domainDocumentInstancesActions } from '../../../store/domain/documentInstances'
import { enabledFeaturesPreferencesSelector } from '../../../store/domain/me'

const mapStateToProps = (state: RootState): MedicalEventContentStoreProps => ({
  selectedMedicalEventDocument: selectedMedicalEventDocumentSelector(state),
  selectedContentFilter: selectedContentFilterSelector(state),
  loading: isMedicalEventContentLoadingSelector(state),
  questionnaire: inUseMedicalEventQuestionnaireWithAnswerValuesSelector(state),
  prescription: inUseMedicalEventPrescriptionSelector(state),
  quoteLine: inUseMedicalEventQuoteLineSelector(state),
  audiometer: inUseMedicalEventAudiometerSelector(state),
  documentQuestionList: inUseDocumentQuestionListWithAnswerValuesSelector(state),
  medicalEvent: inUseMedicalEventSelector(state),
  userEnabledFeatures: enabledFeaturesPreferencesSelector(state),
})

const mapDispatchToProps: MedicalEventContentDispatchProps = {
  selectMedicalEventContent: medicalEventContentUiActions.selectMedicalEventContent,
  addFilesToMedicalEvent: medicalEventDomainActions.addFilesToMedicalEvent,
  selectMedicalEventDocument: medicalEventUiActions.selectMedicalEventDocument,
  lockDocument: domainDocumentInstancesActions.lockDocument,
  updateMedicalEvent: medicalEventDomainActions.updateMedicalEvent,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(MedicalEventContent)
export { withConnect as MedicalEventContent }
