import { FollowTypes } from '@follow/cdk'

export { AUDIOGRAM_DASHED_MODES, AUDIOGRAM_SOLID_MODES } from '@follow/cdk'

export type AudiometerInstance = FollowTypes.Audiometry.AudiometerInstance
export type AudiometerTemplate = FollowTypes.Audiometry.AudiometerTemplate
export type EarSide = FollowTypes.Audiometry.EarSide
export type SimplePoint = FollowTypes.Audiometry.SimplePoint
export type AxisParams = FollowTypes.Audiometry.AxisParams
export type HearablePoint = FollowTypes.Audiometry.HearablePoint
export type AudiogramDashedMode = FollowTypes.Audiometry.AudiogramDashedMode
export type AudiogramSolidMode = FollowTypes.Audiometry.AudiogramSolidMode
export type AudiogramStrokeMode = FollowTypes.Audiometry.AudiogramStrokeMode
export type EarSidePointImageDict = FollowTypes.Audiometry.EarSidePointImageDict
export type WeberCellValue = FollowTypes.Audiometry.WeberCellValue
export type WeberTestContent = FollowTypes.Audiometry.WeberTestContent
export type WeberTest = FollowTypes.Audiometry.WeberTest
export type ToneAudiogramScores = FollowTypes.Audiometry.ToneAudiogramScores
export type AudiogramIconOptions = FollowTypes.Audiometry.AudiogramIconOptions
export type SpeechAudiogram = FollowTypes.Audiometry.SpeechAudiogram
export type ToneAudiogram = FollowTypes.Audiometry.ToneAudiogram
export type Tympanogram = FollowTypes.Audiometry.Tympanogram

export const AUDIOGRAM_INPUT_DEBOUNCE_DELAY = 1000
