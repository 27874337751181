import React, { FC } from 'react'
import { AudiogramPointProps, audiogramPointSize } from '../../AudiogramPoint.model'

export const CrossPoint: FC<AudiogramPointProps> = ({ color, lowerChevron }) => {
  return (
    <svg
      width={audiogramPointSize}
      height={audiogramPointSize}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <g fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
        <line x1="10.25" y1="10.25" x2="21.75" y2="21.75" />
        <line x1="21.75" y1="10.25" x2="10.25" y2="21.75" />

        {lowerChevron && <polyline id="chevron" points="18,24 21,27 24,24" />}
      </g>
    </svg>
  )
}
