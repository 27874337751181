import { useMutation } from '@tanstack/react-query'
import { createAudiometerInstance } from '../../../data/audiometry'
import { UserFriendlyError } from '../../../model/Error'

interface UseCreateAudiometerInstanceVariables {
  documentId: number
}

export const useCreateAudiometerInstance = () => {
  const mutation = useMutation({
    mutationFn: async ({ documentId }: UseCreateAudiometerInstanceVariables) => {
      const { data, ok } = await createAudiometerInstance(documentId)

      if (!data || !ok) {
        throw new UserFriendlyError(
          'error',
          "La création d'audiométrie a échoué",
          "Une erreur technique s'est produite",
        )
      }

      return data
    },
  })

  return mutation
}
