import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateSpeechAudiogram } from '../../../data/audiometry'
import { SpeechAudiogram } from '../../../model/Audiometry'
import { UserFriendlyError } from '../../../model/Error'
import { refetchVariableDataOnEditor } from '../../../store/renderer'
import { useActionDispatch } from '../../utils'
import { audiometryKeys } from './audiometry.keys'

interface UseUpdateSpeechAudiogramParams {
  audiogramId: number
  payload: Partial<SpeechAudiogram>
}

export const useUpdateSpeechAudiogram = () => {
  const queryClient = useQueryClient()

  const refetchVariablesIfEditorIsActive = useActionDispatch(refetchVariableDataOnEditor)

  const mutation = useMutation({
    mutationFn: async ({ audiogramId, payload }: UseUpdateSpeechAudiogramParams) => {
      const { data, ok } = await updateSpeechAudiogram(audiogramId, payload)

      if (!data || !ok) {
        throw new UserFriendlyError('error', "La mise à jour de l'audiogramme vocal a échoué")
      }

      return data
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: audiometryKeys.audiometerInstance(data.audiometerId),
      })
      refetchVariablesIfEditorIsActive()
    },
  })

  return mutation
}
