import {
  DangerousHtml,
  EditorVariableDisplayConfig,
  isAudiometerVariable,
  isBaseVariable,
  isManualPrescriptionVariable,
  isPrescriptionVariable,
  isQuestionnaireVariable,
  isQuestionVariable,
  isQuoteLineVariable,
  retrieveVariableId,
  retrieveVariableUuid,
} from '@follow/cdk'
import { EditorValue, listVariables, VariablesData } from '@follow/farte'
import { computeDrugVariableData, computeManualPrescription } from '../../../misc/drug.utilities'
import { FarteDocumentTemplate } from '../../../model/DocumentTemplate'
import {
  BASE_VARIABLES_LIST,
  DEFAULT_VARIABLE_THEME,
  EMPTY_VARIABLE_THEME,
} from '../../../model/DocumentVariable'
import { RootState } from '../../reducers'
import audiometryPlaceholder from '../../../assets/images/audiometer_placeholder.jpg'
export const pendingVariablesSelector = (state: RootState) => state.domain.editor.pendingVariables

// Voir https://fwhealth.atlassian.net/browse/DEV-3321

export function getDisplayConfigLabel(displayConfig?: EditorVariableDisplayConfig) {
  switch (displayConfig) {
    case EditorVariableDisplayConfig.HIDDEN:
      return 'masqué'
    case EditorVariableDisplayConfig.SCORE_VALUE:
      return 'score uniquement'
    case EditorVariableDisplayConfig.TITLE:
      return 'titre seulement'
    case EditorVariableDisplayConfig.TITLE_AND_SCORE_VALUE:
      return 'titre+score'
    case EditorVariableDisplayConfig.VARIABLE_TITLE_ONLY:
      return 'titre seulement'
    case EditorVariableDisplayConfig.FULL:
      return 'complet'
    default:
      return 'par défaut'
  }
}

export function documentVariablesDataSelector(
  value: EditorValue,
  {
    prescriptions,
    questionnaires,
    quoteLines,
    variables,
    manualPrescriptions,
  }: FarteDocumentTemplate,
): VariablesData {
  // Single Source of Truth
  return listVariables(value.toJSON()).reduce<VariablesData>((variablesData, { id, context }) => {
    switch (true) {
      case isPrescriptionVariable(id): {
        const retrivedDrugVariableUuid = retrieveVariableUuid(id)
        const prescription = prescriptions.find(
          ({ prescriptionVariableUuid }) => prescriptionVariableUuid === retrivedDrugVariableUuid,
        )
        variablesData[id] = {
          id,
          type: 'render',
          value: {
            renderer: DangerousHtml,
            props: {
              html: prescription ? `<span>${computeDrugVariableData(prescription)}</span>` : null,
            },
            displayMode: 'inline',
          },
          customTheme: DEFAULT_VARIABLE_THEME,
          fallbackValue: {
            html: `Ajout en cours ...`,
          },
        }
        break
      }
      case isManualPrescriptionVariable(id): {
        const manualPrescriptionVariableUuid = retrieveVariableUuid(id)
        const manualPrescription = manualPrescriptions.find(
          ({ variableUuid }) => variableUuid === manualPrescriptionVariableUuid,
        )
        if (manualPrescription) {
          variablesData[id] = {
            id: manualPrescription.variableUuid,
            type: 'render',
            value: {
              renderer: DangerousHtml,
              props: {
                html: manualPrescription
                  ? `<span>${computeManualPrescription(manualPrescription)}</span>`
                  : null,
              },
              displayMode: 'inline',
            },
            customTheme: DEFAULT_VARIABLE_THEME,
            fallbackValue: {
              html: `Ajout en cours ...`,
            },
          }
        } else {
          console.warn(
            `Impossible d'hydrater la variable ${id}, manualPrescriptionUuid: ${manualPrescriptionVariableUuid}`,
          )
        }
        break
      }
      case isQuestionnaireVariable(id): {
        const questionnaireId = retrieveVariableId(id)
        const questionnaire = questionnaires.find(({ id: itemId }) => itemId === questionnaireId)
        variablesData[id] = {
          id,
          type: 'normal',
          value: questionnaire
            ? `${questionnaire.title || `<Variable sans titre>`} [${getDisplayConfigLabel(
                context.displayConfig as EditorVariableDisplayConfig,
              )}]`
            : null,
          customTheme: EMPTY_VARIABLE_THEME,
          fallbackValue: `Ajout en cours ...`,
        }
        break
      }
      case isQuestionVariable(id): {
        const questionId = retrieveVariableId(id)
        const question = variables.find(({ id: itemId }) => itemId === questionId)
        const questionValue = question
          ? `${question.title} (${getDisplayConfigLabel(
              context.displayConfig as EditorVariableDisplayConfig,
            )})`
          : `Ajout en cours ...`

        variablesData[id] = {
          id,
          type: 'normal',
          value: questionValue,
          fallbackValue: `Ajout en cours ...`,
        }
        break
      }
      case isQuoteLineVariable(id): {
        const quoteLineUuid = retrieveVariableUuid(id)
        const quoteLine = quoteLines.find(({ variableUuid }) => variableUuid === quoteLineUuid)

        if (quoteLine) {
          variablesData[id] = {
            id,
            type: 'render',
            value: {
              renderer: DangerousHtml,
              props: {
                html: `<span>${quoteLine.summary}</span>`,
              },
              displayMode: 'block',
            },
            customTheme: DEFAULT_VARIABLE_THEME,
            fallbackValue: {
              html: `Ajout en cours ...`,
            },
          }
        } else {
          console.warn(`Impossible d'hydrater la variable ${id}, quoteLineUuid: ${quoteLineUuid}`)
        }
        break
      }
      case isAudiometerVariable(id): {
        variablesData[id] = {
          id,
          type: 'render',
          value: {
            renderer: DangerousHtml,
            props: {
              html: `<img src="${audiometryPlaceholder}" />`,
            },
            displayMode: 'block',
          },
          customTheme: DEFAULT_VARIABLE_THEME,
          fallbackValue: {
            html: `Ajout en cours ...`,
          },
        }

        break
      }
      case isBaseVariable(id): {
        variablesData[id] = BASE_VARIABLES_LIST[id]
        break
      }
      default: {
        console.warn(`[VARIABLES] Unknown variable ${id}`)
        break
      }
    }
    return variablesData
  }, {})
}
