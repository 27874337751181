import { isDefined, VariableKindPrefix } from '@follow/cdk'
import {
  AudiogramDashedMode,
  AudiogramSolidMode,
  AUDIOGRAM_DASHED_MODES,
  AUDIOGRAM_SOLID_MODES,
  SimplePoint,
  AudiogramStrokeMode,
  AudiometerInstance,
  AudiometerTemplate,
  HearablePoint,
} from '../../model/Audiometry'

export const togglePoint = <Point extends SimplePoint = SimplePoint>(
  point: Point,
  data: Array<Point>,
) => {
  const initialDataLength = data.length
  const filteredData = data.filter(({ x, y }) => x !== point.x || y !== point.y)

  if (filteredData.length !== initialDataLength) {
    return filteredData
  } else {
    return [...data, point]
  }
}

export const isSolidStrokeMode = (mode: AudiogramStrokeMode): mode is AudiogramSolidMode => {
  return (AUDIOGRAM_SOLID_MODES as ReadonlyArray<AudiogramStrokeMode>).includes(mode)
}
export const isDashedStrokeMode = (mode: AudiogramStrokeMode): mode is AudiogramDashedMode => {
  return (AUDIOGRAM_DASHED_MODES as ReadonlyArray<AudiogramStrokeMode>).includes(mode)
}

export const computeAudiometerVariableId = (
  audiometer: AudiometerInstance | AudiometerTemplate,
) => {
  return `${VariableKindPrefix.AUDIOMETER}_${audiometer.id}`
}

const serializePoint = (point: SimplePoint | HearablePoint) => {
  const { x, y } = point
  const unheard = 'unheard' in point && isDefined(point.unheard) ? `:${point.unheard}` : ''

  return `${x}:${y}${unheard}`
}
export const arePointArrayEquivalent = <Point extends SimplePoint>(
  array1: Array<Point>,
  array2: Array<Point>,
) => {
  const set1 = new Set(array1.map(serializePoint))
  const set2 = new Set(array2.map(serializePoint))

  const mergedSet = new Set([...set1, ...set2])

  return mergedSet.size === set1.size && mergedSet.size === set2.size
}
