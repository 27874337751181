export const audiogramPointSize = '48px'

export interface AudiogramPointProps {
  color: string
  lowerChevron?: boolean
}

export interface AudiogramRotativePointProps extends AudiogramPointProps {
  orientation: 'left' | 'right'
}

export interface AudiogramComplexPointProps extends AudiogramPointProps {
  fill?: boolean
  point?: boolean
}
