import styled from '@emotion/styled'
import colorsv3 from '../../../styles/variables/_colors_v3.scss'

const borderWidth = ' 2px'
const borderColor = colorsv3.shade5

const Container = styled.div`
  border-radius: 4px;
  border: ${borderWidth} solid ${borderColor};
`

const Grid = styled.table`
  color: ${colorsv3.shade4};
  font-weight: 500;
  border-spacing: 0;
  border-collapse: collapse;

  th {
    width: 100px;
    user-select: none;
  }

  tr > * {
    border-style: none solid;
    border-width: ${borderWidth};
    border-color: ${borderColor};
    text-align: center;
    padding: 4px 8px;

    &:first-of-type {
      border-left: none;
    }

    &:last-of-type {
      border-right: none;
    }
  }

  tr:first-of-type {
    border-bottom: ${borderWidth} solid ${borderColor};
  }
`

export const WeberTestStyled = {
  Container,
  Grid,
}
