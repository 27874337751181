import { Suspense } from 'react'
import { lazyWithRetry } from '../../../misc/lazy.utilities'
import { Loader } from '../../shared'
import { AudiometerFormProps } from './AudiometerForm.model'

const LazyAudiometerForm = lazyWithRetry(() =>
  /* webpackPreload: false, webpackChunkName: "audiometry" */ import(
    './AudiometerForm.component'
  ).then(({ AudiometerForm }) => AudiometerForm),
)

export const AudiometerForm = (props: AudiometerFormProps) => (
  <Suspense fallback={<Loader />}>
    <LazyAudiometerForm {...props} />
  </Suspense>
)
