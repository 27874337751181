import React, { FC } from 'react'
import { AudiogramComplexPointProps, audiogramPointSize } from '../../AudiogramPoint.model'

export const TrianglePoint: FC<AudiogramComplexPointProps> = ({
  color,
  fill,
  lowerChevron,
  point,
}) => {
  return (
    <svg
      width={audiogramPointSize}
      height={audiogramPointSize}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
    >
      <g fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
        <polygon id="triangle" fill={fill ? color : undefined} points="16 8.75,9.75 20,22.25 20" />
        {point && <circle id="point" cx="16" cy="16" r="2" fill={color} />}
        {lowerChevron && <polyline id="chevron" points="18,22 21,25 24,22" />}
      </g>
    </svg>
  )
}
