import { JSONToValue, listVariables, removeVariable, valueToJSON } from '@follow/farte'
import { call, put, race, select, take, takeEvery } from 'redux-saga/effects'
import { Questionnaire } from '../../../model/Questionnaire'
import { documentInstancesActions } from '../../cache/documentInstances/index'
import { addError, addValid } from '../../message/index'
import { RESTUX_IDENTIFIER } from '../../restux.identifier'
import { generateIdentifier } from '../../restux/restux.utilities'
import { hideBottomPanel } from '../../ui/bottomPanel/index'
import {
  documentWithSingleSourceOfTruthMapper,
  craftAMinimalTypescriptCompatibleDocumentUpdate,
  pendingVariablesSelector,
  PendingVariables,
} from '../editor'
import { domainDocumentInstancesActions } from './documentInstances.actions'
import { DomainDocumentInstancesActionTypes } from './documentInstances.model'
import { DocumentInstance, FarteDocumentInstance } from '../../../model/DocumentInstance'
import { lockDocumentInstance, lockMultipleDocuments, renewPrescription } from './api'
import { ApiResponse } from 'apisauce'
import {
  inUseMedicalEventDocumentIdSelector,
  inUseMedicalEventDocumentSelector,
} from '../../ui/medicalEvents/medicalEventDocumentInstances'
import { getVariableData, variableDataSelector } from '../../renderer'
import { inUseMedicalEventIdSelector, medicalEventUiActions } from '../../ui/medicalEvents'
import { MedicalEventDocumentType } from '../../../model/MedicalEvent'
import { documentAlertsDomainActions } from '../documentAlerts'
import { AnyAction } from 'redux'
import { medicalEventsActions } from '../../cache/medicalEvents'
import { getCurrentPatientId } from '../../../misc/currentPatient.utilities'
import { difference, xor } from 'lodash'
import {
  isDefined,
  isManualPrescriptionVariable,
  isPrescriptionVariable,
  isQuestionVariable,
} from '@follow/cdk'
import { domainEditorActions } from '../editor/editor.actions'
import { medicalEventDomainActions } from '../medicalEvents'
import { queryClient } from '../../../App'
import { patientStatisticsKeys } from '../../../hooks/queries/patientStatistics/patientStatistics.keys'
import { documentKeys } from '../../../hooks/queries/documents/documents.keys'
import { patientImportantInformationsKeys } from '../../../hooks/queries/patientImportantInformations/patientImportantInformations.keys'
import { documentAnswerValuesListSelector } from '../documentAnswerValues'
import { AnswerValue } from '../../../model/AnswerValue'
import { deleteDocumentAnswerValue } from '../documentAnswerValues/api'
import { medicalEventsKeys } from '../../../hooks/queries/medicalEvents/medicalEvents.keys'
import { patientManualPrescriptionsKeys } from '../../../hooks/queries/patientManualPrescriptions'
import { patientTreatmentsKeys } from '../../../hooks/queries/patientTreatments/patientTreatments.keys'

const removeQuestionsAnswer = async (
  ids: string[],
  answerValues: readonly AnswerValue[],
  patientId: number | null,
) => {
  await Promise.all(
    ids.map(async (questionIdToRemove) => {
      const filteredAnswerValues = answerValues.filter(
        (answer) => answer.questionId === Number(questionIdToRemove),
      )
      await Promise.all(
        filteredAnswerValues.map(async (answer) => await deleteDocumentAnswerValue(answer.id)),
      )
    }),
  )
  patientId
    ? queryClient.invalidateQueries({
        queryKey: patientImportantInformationsKeys.listAll(patientId),
      })
    : queryClient.invalidateQueries({ queryKey: patientImportantInformationsKeys.lists })
}

function* addQuestionnaireToDocumentInstanceWorker({
  documentInstance,
  questionnaires,
}: ReturnType<typeof domainDocumentInstancesActions.addQuestionnaire>) {
  const taskIdentifier = generateIdentifier(RESTUX_IDENTIFIER.documentInstanceEdition)

  const newQuestionnaires = [
    ...documentInstance.questionnaires,
    ...questionnaires,
  ] as Questionnaire[] // PARCE QUE !!!
  yield put(
    documentInstancesActions.actions.apiUpdateItem(
      documentInstance.id,
      {
        questionnaires: newQuestionnaires,
        type: documentInstance.type,
      },
      { identifier: taskIdentifier },
    ),
  )
  yield put(addValid('Ajout en cours'))
  const {
    succeed,
  }: {
    succeed: ReturnType<typeof documentInstancesActions.actions.storeSetItemDetails>
  } = yield race({
    succeed: take(documentInstancesActions.types.STORE_SET_ITEM_DETAILS),
    failed: take(documentInstancesActions.types.DISPATCH_ERROR),
  })
  if (succeed && succeed.identifier === taskIdentifier) {
    yield put(hideBottomPanel())
  }
}

function* addQuestionnaireToDocumentInstanceWatcher() {
  yield takeEvery(
    DomainDocumentInstancesActionTypes.ADD_QUESTIONNAIRE,
    addQuestionnaireToDocumentInstanceWorker,
  )
}

function* removeVariableFromDocumentInstanceWorker({
  documentInstance,
  variableId,
}: ReturnType<typeof domainDocumentInstancesActions.removeVariable>) {
  const taskIdentifier = generateIdentifier(RESTUX_IDENTIFIER.documentInstanceEdition)
  const documentValue = JSONToValue(documentInstance.template)
  const newDoc = removeVariable(documentValue, variableId)
  const updates = documentWithSingleSourceOfTruthMapper(valueToJSON(newDoc), true)
  const inUseDocumentInstance: DocumentInstance | null = yield select(
    inUseMedicalEventDocumentSelector,
  )
  const craftedUpdate = craftAMinimalTypescriptCompatibleDocumentUpdate(
    updates,
    documentInstance,
  ) as Partial<DocumentInstance>

  yield put(
    documentInstancesActions.actions.apiUpdateItem(documentInstance.id, craftedUpdate, {
      identifier: taskIdentifier,
    }),
  )

  const { succeed }: ReturnType<typeof documentInstancesActions.actions.storeSetItemDetails> =
    yield race({
      succeed: take(
        (action: AnyAction) =>
          action.type === documentInstancesActions.types.STORE_SET_ITEM_DETAILS &&
          action.identifier === taskIdentifier,
      ),
      failed: take(documentInstancesActions.types.DISPATCH_ERROR),
    })
  if (
    succeed &&
    inUseDocumentInstance &&
    inUseDocumentInstance?.type === 'farte' &&
    updates.prescriptionUuids.length !== inUseDocumentInstance.prescriptions.length
  ) {
    yield put(documentAlertsDomainActions.getAlerts())

    const patientId = getCurrentPatientId()
    if (isDefined(patientId)) {
      queryClient.invalidateQueries({ queryKey: patientTreatmentsKeys.lists(patientId) })
    }
  }
}

function* removeVariableFromDocumentInstanceWatcher() {
  yield takeEvery(
    DomainDocumentInstancesActionTypes.REMOVE_VARIABLE,
    removeVariableFromDocumentInstanceWorker,
  )
}

function* updateDocumentInstanceValueSagaWorker({
  documentInstanceId,
  documentInstanceValue,
  forceVariableDataRefetch,
  successCallback,
}: ReturnType<typeof domainDocumentInstancesActions.updateDocumentInstanceValue>) {
  const taskIdentifier = generateIdentifier(RESTUX_IDENTIFIER.documentInstanceEdition)
  const currentJsonValue = valueToJSON(documentInstanceValue)
  const updates = documentWithSingleSourceOfTruthMapper(currentJsonValue, true)

  const inUseDocumentInstance: DocumentInstance | null = yield select(
    inUseMedicalEventDocumentSelector,
  )

  if (inUseDocumentInstance?.type === 'farte' && !inUseDocumentInstance.locked) {
    const patientId = getCurrentPatientId()
    // On construit une instance de document suffisante pour l'API
    const pendingVariables: PendingVariables = yield select(pendingVariablesSelector)
    const craftedDocumentInstance = craftAMinimalTypescriptCompatibleDocumentUpdate(
      updates,
      inUseDocumentInstance,
      pendingVariables,
    ) as unknown as Partial<FarteDocumentInstance>
    yield put(
      documentInstancesActions.actions.apiUpdateItem(documentInstanceId, craftedDocumentInstance, {
        identifier: taskIdentifier,
      }),
    )
    const { succeed }: ReturnType<typeof documentInstancesActions.actions.storeSetItemDetails> =
      yield race({
        succeed: take(
          (action: AnyAction) =>
            action.type === documentInstancesActions.types.STORE_SET_ITEM_DETAILS &&
            action.identifier === taskIdentifier,
        ),
        failed: take(documentInstancesActions.types.DISPATCH_ERROR),
      })

    if (!succeed) {
      return
    }

    if (successCallback) {
      successCallback(succeed.item)
    }

    if (patientId && craftedDocumentInstance.questionnaires?.length) {
      queryClient.invalidateQueries({ queryKey: patientStatisticsKeys.detail(patientId) })
    }
    yield put(domainEditorActions.clearPendingVariables())

    const currentVariableData = yield select(variableDataSelector)
    const currentVariableIds = Object.keys(currentVariableData ?? {})
    const newVariableIds = listVariables(updates.template).map(({ id }) => id)

    const variableDiff = difference(newVariableIds, currentVariableIds)
    const variableXor = xor(newVariableIds, currentVariableIds)

    // Si des variables présentes dans le doc n'ont pas de variable data
    if (forceVariableDataRefetch || variableDiff.length > 0) {
      yield put(getVariableData())
    }

    // Si ajout ou retrait de variable prescription
    if (variableXor.some(isPrescriptionVariable)) {
      yield put(documentAlertsDomainActions.getAlerts())

      if (isDefined(patientId)) {
        queryClient.invalidateQueries({ queryKey: patientTreatmentsKeys.lists(patientId) })
      }
    }

    // Si ajout ou retrait de variable prescription manuelle
    if (variableXor.some(isManualPrescriptionVariable)) {
      if (patientId) {
        queryClient.invalidateQueries({ queryKey: patientManualPrescriptionsKeys.list(patientId) })
      }
    }

    /**
     * si on ajoute un document et qu'il contient des variables avec informations importantes on refetch les informations importantes
     * cela permet de couvrir le cas où on ajoute un document avec des réponses partagées taggées en importantes
     * (currentVariableIds contient des éléments uniquement lorsqu'on supprime une variable)
     */
    if (!currentVariableIds.length) {
      const documentHasImportantInformations = inUseDocumentInstance.variables.some(
        (variable) => !!variable.importantInformation,
      )
      if (documentHasImportantInformations) {
        patientId
          ? queryClient.invalidateQueries({
              queryKey: patientImportantInformationsKeys.listAll(patientId),
            })
          : queryClient.invalidateQueries({ queryKey: patientImportantInformationsKeys.lists })
      }
    }

    /**
     * currentVariableIds contient des éléments uniquement lorsqu'on supprime une variable:
     * il liste toutes les variables présentes dans le doc
     */
    if (currentVariableIds.length && variableXor.some(isQuestionVariable)) {
      const questionVariablesToRemove = variableXor.filter(isQuestionVariable)
      if (questionVariablesToRemove.length) {
        const ids = questionVariablesToRemove.map((questionId) =>
          questionId.replace('question_', ''),
        )

        // on récupère uniquement les ids des question à supprimer qui sont taggées en tant qu'information importante
        const importantInfoQuestionIds = ids.filter((id) =>
          inUseDocumentInstance.variables.some(
            (variable) => variable.id === Number(id) && variable.importantInformation,
          ),
        )

        const answerValues: ReturnType<typeof documentAnswerValuesListSelector> = yield select(
          documentAnswerValuesListSelector,
          inUseDocumentInstance.id,
        )
        if (answerValues && !!importantInfoQuestionIds.length) {
          removeQuestionsAnswer(importantInfoQuestionIds, answerValues, patientId)
        }
      }
    }
  }
  queryClient.invalidateQueries({ queryKey: documentKeys.detail(Number(documentInstanceId)) })
}

function* updateDocumentInstanceValueSagaWatcher() {
  yield takeEvery(
    DomainDocumentInstancesActionTypes.UPDATE_VALUE,
    updateDocumentInstanceValueSagaWorker,
  )
}

function* updateDocumentInstanceWithVariableDataRefetchWorker({
  id,
  documentInstance,
}: ReturnType<
  typeof domainDocumentInstancesActions.updateDocumentInstanceWithVariableDataRefetch
>) {
  const taskIdentifier = generateIdentifier(RESTUX_IDENTIFIER.documentInstanceEdition)
  if (documentInstance.type === 'pdf') {
    yield put(
      documentInstancesActions.actions.apiUpdateItem(id, documentInstance, {
        identifier: taskIdentifier,
      }),
    )
  } else {
    yield put(medicalEventDomainActions.updateDocumentThenFetchEvent(id, documentInstance))
  }
  const { succeed }: ReturnType<typeof documentInstancesActions.actions.storeSetItemDetails> =
    yield race({
      succeed: take(
        (action: AnyAction) =>
          action.type === documentInstancesActions.types.STORE_SET_ITEM_DETAILS &&
          action.identifier === taskIdentifier,
      ),
      failed: take(documentInstancesActions.types.DISPATCH_ERROR),
    })

  if (!succeed) {
    return
  }

  queryClient.invalidateQueries({ queryKey: documentKeys.detail(id) })
  yield put(getVariableData())
}

function* updateDocumentInstanceWithVariableDataRefetchWatcher() {
  yield takeEvery(
    DomainDocumentInstancesActionTypes.UPDATE_DOCUMENT_WITH_VARIABLEDATA_REFETCH,
    updateDocumentInstanceWithVariableDataRefetchWorker,
  )
}

function* renewPrescriptionWorker({
  documentId,
}: ReturnType<typeof domainDocumentInstancesActions.renewPrescription>) {
  const response: ApiResponse<DocumentInstance> = yield call(renewPrescription, documentId)
  if (response.ok && response.data) {
    yield put(domainDocumentInstancesActions.lockDocument(documentId, true, false))
    const { medicalEventId: newMedicalEventId, id: newDocumentId } = response.data
    yield put(medicalEventUiActions.useId(newMedicalEventId))
    yield put(
      medicalEventUiActions.selectMedicalEventDocument({
        medicalEventDocumentType: MedicalEventDocumentType.FW_DOCUMENT,
        id: newDocumentId,
      }),
    )
    queryClient.invalidateQueries({ queryKey: medicalEventsKeys.lists })
  } else {
    yield put(
      addError('Erreur lors du renouvellement', "Le renouvellement de l'ordonnance a échoué."),
    )
  }
}

function* renewPrescriptionWatcher() {
  yield takeEvery(DomainDocumentInstancesActionTypes.RENEW_PRESCRIPTION, renewPrescriptionWorker)
}

function* lockDocumentWorker({
  documentId,
  locked,
  refreshDocumentLocked = true,
}: ReturnType<typeof domainDocumentInstancesActions.lockDocument>) {
  const response = yield call(lockDocumentInstance, documentId, locked)

  if (response.ok && response.data) {
    yield put(medicalEventsActions.actions.apiGetItemDetails(response.data.medicalEventId))

    if (refreshDocumentLocked) {
      //Cela force le reload du component de l'éditeur après un lock
      yield put(
        medicalEventUiActions.selectMedicalEventDocument({
          medicalEventDocumentType: MedicalEventDocumentType.OBSERVATIONS,
        }),
      )
      yield put(
        medicalEventUiActions.selectMedicalEventDocument({
          medicalEventDocumentType: MedicalEventDocumentType.FW_DOCUMENT,
          id: documentId,
        }),
      )
    }
  }
}

function* lockDocumentWatcher() {
  yield takeEvery(DomainDocumentInstancesActionTypes.LOCK_DOCUMENT, lockDocumentWorker)
}

function* lockMultipleDocumentsWorker({
  documentIds,
  locked,
}: ReturnType<typeof domainDocumentInstancesActions.lockMultipleDocuments>) {
  const response = yield call(lockMultipleDocuments, documentIds, locked)
  const inUseDocumentId: number | null = yield select(inUseMedicalEventDocumentIdSelector)
  if (response.ok && inUseDocumentId !== null) {
    const inUseMedicalEventId: number | null = yield select(inUseMedicalEventIdSelector)
    if (inUseMedicalEventId !== null) {
      yield put(medicalEventsActions.actions.apiGetItemDetails(inUseMedicalEventId))
    }

    //Force le démontage du component de l'éditeur pour prendre en compte le lock
    yield put(
      medicalEventUiActions.selectMedicalEventDocument({
        medicalEventDocumentType: MedicalEventDocumentType.OBSERVATIONS,
      }),
    )
    yield put(
      medicalEventUiActions.selectMedicalEventDocument({
        medicalEventDocumentType: MedicalEventDocumentType.FW_DOCUMENT,
        id: inUseDocumentId,
      }),
    )
  }
}

function* lockMultipleDocumentsWatcher() {
  yield takeEvery(
    DomainDocumentInstancesActionTypes.LOCK_MULTIPLE_DOCUMENTS,
    lockMultipleDocumentsWorker,
  )
}

export const domainDocumentInstancesSagas = {
  addQuestionnaireToDocumentInstanceWatcher,
  removeQuestionnaireFromDocumentInstanceWatcher: removeVariableFromDocumentInstanceWatcher,
  updateDocumentInstanceValueSagaWatcher,
  lockDocumentWatcher,
  renewPrescriptionWatcher,
  lockMultipleDocumentsWatcher,
  updateDocumentInstanceWithVariableDataRefetchWatcher,
}
