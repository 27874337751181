import isHotkey from 'is-hotkey'
import { useCallback, useEffect, DependencyList } from 'react'

export enum HotKeys {
  ModQ = 'mod+q',
  ModP = 'mod+p',
  ModS = 'mod+s',
  ModShiftS = 'mod+shift+s',
  ModShitV = 'mod+shift+v',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  Enter = 'Enter',
  One = '1',
  Two = '2',
  Three = '3',
  Four = '4',
  Five = '5',
  Six = '6',
}

interface HotKeyOptions {
  ignoreInputEvents?: boolean
}

export const useHotKeyEffect = (
  hotkey: HotKeys,
  callback: (event: KeyboardEvent) => void,
  deps: DependencyList,
  options?: HotKeyOptions,
) => {
  const handleDocumentKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (options?.ignoreInputEvents && event.target instanceof HTMLInputElement) return

      if (isHotkey(hotkey, event)) {
        callback(event)
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [...deps, callback, hotkey],
  )
  useEffect(() => {
    window.document.addEventListener('keydown', handleDocumentKeyDown, true)
    return () => {
      window.document.removeEventListener('keydown', handleDocumentKeyDown, true)
    }
  }, [handleDocumentKeyDown])
}
